import React from 'react'
import TableComponent from '../ui/Table'

const tableHeaders = ['Sl No', 'Keyword', 'Actions']
const ListSearchKeywords = () => {
  return (
   <TableComponent tableHeaders={tableHeaders} data={[]}/>
  )
}

export default ListSearchKeywords