import React, { Fragment, useContext, useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import useHttp from "../../hooks/use-http";
import { login } from "../../lib/api";
import AuthContext from "../../store/auth-context";

const UserLogin = (props) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const authCtx = useContext(AuthContext);

  const { sendRequest, data, status, error } = useHttp(login);

  const userNameChangeHandler = (event) => {
    setUserName(event.target.value);
  };
  const passwordChangeHandler = (event) => {
    setPassword(event.target.value);
  };

  const loginClickHandler = (event) => {
    event.preventDefault();
    const credentials = { username: userName, password: password };
    sendRequest(credentials);
  };
  
  useEffect(()=>{
    if(data){
        authCtx.login(data)
    props.handleClose();
    }
  },[authCtx, data, props])

  const cancelClickHandler = (event) => {
    event.preventDefault();
    props.handleClose();
  };
  return (
    <Fragment>
      <Form>
        <Form.Group className="mb-3" controlId="formUserName">
          <Form.Label>User Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="User Name"
            value={userName}
            onChange={userNameChangeHandler}
            autoFocus
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={passwordChangeHandler}
          />
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Col>
            <Button variant="primary" type="submit" onClick={loginClickHandler}>
              Login
            </Button>{" "}
            <Button type="cancel" onClick={cancelClickHandler}>
              Cancel
            </Button>
          </Col>
        </Form.Group>
      </Form>
    </Fragment>
  );
};

export default UserLogin;
