import React, { Fragment, useState, useEffect } from "react";
import useHttp from "../../hooks/use-http";
import { addNewSite, getAllCustomers } from "../../lib/api";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

const AddSite = () => {
  const history = useHistory();
  const [customerID, setCustomerID] = useState("");
  const [siteName, setSiteName] = useState("");
  const [siteStatus, setSiteStatus] = useState(false);

  const [customerList, setCustomerList] = useState([
    <option value="select" key="select">
      Select Customer
    </option>,
  ]);

  const {
    sendRequest: getAllCustomersRequest,
    status,
    data: customerData,
    error,
  } = useHttp(getAllCustomers);

  const {
    sendRequest: addSite,
    status: statusSite,
    data: addSiteData,
    erro: addSiteError,
  } = useHttp(addNewSite);

  useEffect(() => {
    getAllCustomersRequest();
  }, [getAllCustomersRequest]);

  const siteNameChangeHandler = (event) => {
    setSiteName(event.target.value);
  };

  const siteStatusChangeHanlder = (event) => {
    setSiteStatus(event.target.checked);
  };

  const customerChangeHandler = (event) => {
    console.log(event.target.value);
    setCustomerID(event.target.value);
  };

  const addSiteClickHandler = (event) => {
    event.preventDefault();
    const siteData = {
      customer: customerID,
      site_name: siteName,
      site_status: siteStatus,
    };
    console.log("Site Data " + siteData);
    addSite(siteData);
    if (!addSiteError) {
      history.push("/site");
    }
  };

  const cancelClickHandler = (event) => {
    event.preventDefault();
    history.push("/site");
  };

  useEffect(() => {
    if (customerData) {
      setCustomerList((prevState) => [
        [<option value="select" key="select">
        Select Customer
      </option>,],
        customerData.map((item, index) => {
          return (
            <option value={item.id} key={index}>
              {item.customer_name}
            </option>
          );
        }),
      ]);
    } else {
      setCustomerList([
        <option value="select" key="select">
          Select Customer
        </option>,
      ]);
    }
  }, [customerData, setCustomerList]);

  return (
    <Fragment>
      <Card>
        <Card.Header as="h5">Add New Site</Card.Header>
        <Card.Body>
          <Form action="" method="post">
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="selectCustomer">Select Customer</Form.Label>
              <Col sm={10}>
                <Form.Select
                  name="selectCustomer"
                  id="selectCustomer"
                  onChange={customerChangeHandler}
                >
                  {customerList}
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="siteName">Site Name</Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="siteName"
                  id="sitename"
                  value={siteName}
                  onChange={siteNameChangeHandler}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label as="legend" column sm={2}>
                Site Active
              </Form.Label>
              <Col sm={10}>
                <Form.Check
                  type="switch"
                  name="siteStatus"
                  id="sitestatus"
                  checked={siteStatus}
                  onChange={siteStatusChangeHanlder}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Col sm={{ span: 10, offset: 2 }}>
                <Button type="submit" onClick={addSiteClickHandler}>
                  Add
                </Button>{" "}
                <Button type="cancel" onClick={cancelClickHandler}>
                  Cancel
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default AddSite;
