import React from 'react'
import {Card} from "react-bootstrap"
import ExtractEventsManual from "../components/eventExtraction/ExtractEventsManual"

const EventExtractionManualTrigger = () => {
  return (
    <Card>
        <Card.Header as="h5">Download Logs/Extract Events</Card.Header>
        <Card.Body>
            <ExtractEventsManual/>
    </Card.Body>
    </Card>
  )
}

export default EventExtractionManualTrigger