// const BASE_URL = "http://erasmus.uksouth.cloudapp.azure.com:8001/api/";
const BASE_URL ="http://erasmus.uksouth.cloudapp.azure.com/api/"
// const BASE_URL = "http://127.0.0.1:8000/api/"

const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + localStorage.getItem("token"),
};

// TODO: For future user system build
// export async function getAllUsers() {
//   const response = await fetch(`${BASE_URL}user/`);
//   const data = await response.json();
// }

export async function login(credentials) {
  let responseData = [];
  const response = await fetch(`${BASE_URL}user/login/`, {
    method: "POST",
    body: JSON.stringify(credentials),
    headers: { "Content-Type": "application/json" },
  });
  const data = await response.json();
  if (response.ok) {
    responseData = data["token"];

    return responseData;
  } else {
    throw new Error(data.message || "Could not Login.");
  }
}

export async function getAllCustomers() {
  let responseData = [];
  const response = await fetch(`${BASE_URL}customer/`);
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch Customers.");
  }

  responseData = data["data"];

  return responseData;
}
export async function getActiveCustomers() {
  let responseData = [];
  const response = await fetch(`${BASE_URL}customer/active_customers/`);
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch Customers.");
  }

  responseData = data["data"];

  return responseData;
}

export async function addNewCustomer(cusotmerData) {
  const response = await fetch(`${BASE_URL}customer/`, {
    method: "POST",
    body: JSON.stringify(cusotmerData),
    headers: headers,
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not add new customer.");
  }
  return data;
}

export async function setCustomerStatus(cusotmerData) {
  const setCustomerStatus = { customer_status: cusotmerData.status };
  const response = await fetch(`${BASE_URL}customer/${cusotmerData.id}/`, {
    method: "PUT",
    body: JSON.stringify(setCustomerStatus),
    headers: headers,
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not update customer status.");
  }
  return data;
}
export async function deleteCustomer(id) {
  const response = await fetch(`${BASE_URL}customer/${id}/`, {
    method: "DELETE",
    headers: headers,
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not Delete customer.");
  }
  return data;
}

export async function getAllSites() {
  let responseData = [];
  const response = await fetch(`${BASE_URL}site/`);
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch Sites.");
  }
  responseData = data["data"];
  return responseData;
}

export async function getSiteByCustomer(id) {
  let responseData = [];
  const response = await fetch(`${BASE_URL}site/${id}/site_by_customer/`);
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch Sites.");
  }
  responseData = data["data"];
  return responseData;
}

export async function addNewSite(siteData) {
  const response = await fetch(`${BASE_URL}site/`, {
    method: "POST",
    body: JSON.stringify(siteData),
    headers: headers,
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not add new site.");
  }
  return data;
}

export async function setSiteStatus(siteData) {
  const setSiteStatus = { site_status: siteData.status };
  const response = await fetch(`${BASE_URL}site/${siteData.id}/`, {
    method: "PUT",
    body: JSON.stringify(setSiteStatus),
    headers: headers,
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not update site status.");
  }
  return data;
}

export async function deleteSite(id) {
  const response = await fetch(`${BASE_URL}site/${id}/`, {
    method: "DELETE",
    headers: headers,
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not Delete Site.");
  }
  return data;
}

export async function getAllRouters() {
  let responseData = [];
  const response = await fetch(`${BASE_URL}router/`);
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch Routers.");
  }
  responseData = data["data"];
  return responseData;
}

export async function setRouterStatus(routerData) {
  const setRouterStatus = { router_status: routerData.status };
  const response = await fetch(`${BASE_URL}router/${routerData.id}/`, {
    method: "PUT",
    body: JSON.stringify(setRouterStatus),
    headers: headers,
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not update router status.");
  }
  return data;
}

export async function addNewRouter(routerData) {
  const response = await fetch(`${BASE_URL}router/`, {
    method: "POST",
    body: JSON.stringify(routerData),
    headers: headers,
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not add new router.");
  }
  return data;
}

export async function getRouterBySite(id) {
  let responseData = [];
  const response = await fetch(`${BASE_URL}router/${id}/router_by_site/`);
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch Routers.");
  }
  responseData = data["data"];
  return responseData;
}

export async function deleteRouter(id) {
  const response = await fetch(`${BASE_URL}router/${id}/`, {
    method: "DELETE",
    headers: headers,
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not Delete Router.");
  }
  return data;
}

export async function getEventExtractionConfiguration() {
  let responseData = [];
  const response = await fetch(`${BASE_URL}config_event_ext/`);
  const data = await response.json();
  if (!response.ok) {
    throw new Error(
      data.message || "Could not fetch Event Extraction Configuration."
    );
  }
  responseData = data["data"];
  return responseData;
}

export async function addEventExtractionConfiguration(
  eventExtractionConfigData
) {
  const response = await fetch(`${BASE_URL}config_event_ext/`, {
    method: "POST",
    body: JSON.stringify(eventExtractionConfigData),
    headers: headers,
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(
      data.message || "Could not add Event Extraction Configuration Data."
    );
  }
  return data;
}

export async function deleteEventExtractionConfiguration(id) {
  const response = await fetch(`${BASE_URL}config_event_ext/${id}/`, {
    method: "DELETE",
    headers: headers,
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(
      data.message || "Could not Event Extraction Configuration."
    );
  }
  return data;
}

export async function getPortsByRouter(routerId) {
  let responseData = [];
  const response = await fetch(
    `${BASE_URL}router_ports/${routerId}/port_by_router/`
  );
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch Router Ports.");
  }
  responseData = data["data"];
  return responseData;
}

export async function addRouterPorts(routerPortsData) {
  const response = await fetch(`${BASE_URL}router_ports/`, {
    method: "POST",
    body: JSON.stringify(routerPortsData),
    headers: headers,
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not Router Ports.");
  }
  return data;
}
export async function getRouterOverview(routerId) {
  let responseData = [];
  const response = await fetch(`${BASE_URL}router/${routerId}/overview/`);
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch Router overview.");
  }
  responseData = data["data"];
  return responseData;
}

export async function getRouterOverviewLog() {
  let responseData = [];
  const response = await fetch(`${BASE_URL}router_overview_log/`);
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch Router overview Logs.");
  }
  responseData = data["data"];
  return responseData;
}

export async function getRevealPasswordLog() {
  let responseData = [];
  const response = await fetch(`${BASE_URL}reveal_pass_log/`);
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch Reveal Password Logs.");
  }
  responseData = data["data"];
  return responseData;
}

export async function getExternalHttpAccessLog() {
  let responseData = [];
  const response = await fetch(`${BASE_URL}ext_http_log/`);
  const data = await response.json();
  if (!response.ok) {
    throw new Error(
      data.message || "Could not fetch External Http access Logs."
    );
  }
  responseData = data["data"];
  return responseData;
}

export async function getPasswordResetLog() {
  let responseData = [];
  const response = await fetch(`${BASE_URL}pass_reset_log/`);
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch Password Reset Logs.");
  }
  responseData = data["data"];
  return responseData;
}
export async function getConnectionLog() {
  let responseData = [];
  const response = await fetch(`${BASE_URL}connection_log/`);
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch Connection Logs.");
  }
  responseData = data["data"];
  return responseData;
}
export async function getCRONLog() {
  let responseData = [];
  const response = await fetch(`${BASE_URL}cron_log/`);
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch CRON Logs.");
  }
  responseData = data["data"];
  return responseData;
}
export async function getSchedulerLog() {
  let responseData = [];
  const response = await fetch(`${BASE_URL}scheduler_log/`);
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch Scheduler Logs.");
  }
  responseData = data["data"];
  return responseData;
}

export async function getExternalHttpAccessBySite(id) {
  let responseData = [];
  const response = await fetch(
    `${BASE_URL}router/${id}/ext_http_access_by_site/`
  );
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch Routers.");
  }

  responseData = data["data"];

  return responseData;
}

export async function toggleExternalHttpAccess(routerData) {
  const response = await fetch(
    `${BASE_URL}router/${routerData.id}/ext_http_access/`,
    {
      method: "POST",
      body: JSON.stringify(routerData),
      headers: headers,
    }
  );
  const data = await response.json();
  if (!response.ok) {
    throw new Error(
      data.message || "Could not add toggle external http access."
    );
  }

  return null;
}

export async function renameRouter(routerData) {
  const response = await fetch(`${BASE_URL}router/${routerData.id}/rename/`, {
    method: "POST",
    body: JSON.stringify(routerData),
    headers: headers,
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not rename Router.");
  }

  return data;
}

export async function getRouterCredentials(id) {
  let responseData = [];
  const response = await fetch(`${BASE_URL}router/${id}/rc/`);
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch Routers.");
  }
  responseData = data["data"];
  return responseData;
}

export async function addShowPasswordLog(id) {
  let responseData = [];
  const response = await fetch(`${BASE_URL}router/${id}/show_password/`, {
    headers: headers,
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not set show password log.");
  }
  responseData = data["data"];
  return responseData;
}

export async function addScheduler(schedulerData) {
  const response = await fetch(`${BASE_URL}scheduler/`, {
    method: "POST",
    body: JSON.stringify(schedulerData),
    headers: headers,
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not add scheduler.");
  }
  return data;
}

export async function getScheduler() {
  let responseData = [];
  const response = await fetch(`${BASE_URL}scheduler/`);
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch Schedulers.");
  }
  responseData = data["data"];
  return responseData;
}

export async function deleteScheduler(id) {
  const response = await fetch(`${BASE_URL}scheduler/${id}/`, {
    method: "DELETE",
    body: JSON.stringify(id),
    headers: headers,
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not delete scheduler.");
  }
  return data;
}

export async function getRouterPortInfoList() {
  let responseData = [];
  const response = await fetch(`${BASE_URL}router_ports_info/port_info_list/`, {
    headers: headers,
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not get port info list.");
  }
  responseData = data["data"];
  return responseData;
}

export async function getLogAnalyzer() {
  let responseData = [];
  const response = await fetch(`${BASE_URL}log_analyzer/`, {
    headers: headers,
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch Log Analyzers.");
  }

  responseData = data["data"];

  return responseData;
}

export async function addLogAnalyzer(logAnalyzerData) {
  const response = await fetch(`${BASE_URL}log_analyzer/`, {
    method: "POST",
    body: JSON.stringify(logAnalyzerData),
    headers: headers,
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not add logAnalyzer.");
  }
  return data;
}

export async function getkeywords() {
  let responseData = [];
  const response = await fetch(`${BASE_URL}log_analyzer/list_keywords/`, {
    headers: headers,
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch keywords.");
  }
  responseData = data["data"];
  return responseData;
}

export async function addKeywords(keywordData) {
  const response = await fetch(`${BASE_URL}log_analyzer/add_keywords/`, {
    method: "POST",
    body: JSON.stringify(keywordData),
    headers: headers,
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not add Keyword.");
  }

  return data;
}

export async function getEventExtractionLogs(id) {
  let responseData = [];
  const response = await fetch(`${BASE_URL}event_extraction_log/${id}/`, {
    headers: { "Content-Type": "application/json" },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch Event Extraction Logs.");
  }
  responseData = data["data"];
  return responseData;
}

export async function downloadEventLogs(id) {
  let responseData = [];
  const response = await fetch(`${BASE_URL}router/${id}/download_logs/`, {
    headers: { "Content-Type": "application/json" },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch Event Logs.");
  }
  responseData = data["message"];
  return responseData;
}

export async function extractEvents(id) {
  let responseData = [];
  const response = await fetch(`${BASE_URL}extract_events/${id}/`, {
    headers: { "Content-Type": "application/json" },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not extract event Logs.");
  }
  responseData = data["message"];
  return responseData;
}


export async function getRouterPortsConfig(id) {
    let responseData = [];
    const response = await fetch(`${BASE_URL}additional_port_config/${id}/get_port_config/`, {
      headers: { "Content-Type": "application/json" },
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Could not fetch Router Port Configurations.");
    }
    responseData = data["data"];
    return responseData;
  }

export async function setRouterPortsConfig(routerPortConfigData) {
    console.log(routerPortConfigData);
    const response = await fetch(`${BASE_URL}additional_port_config/${routerPortConfigData.id}/set_port_config/`, {
      method: "POST",
      body: JSON.stringify(routerPortConfigData),
      headers: headers,
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Could not set router port configuration.");
    }
  
    return data;
  }
  