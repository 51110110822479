import React, { useEffect } from "react";
import useHttp from "../../hooks/use-http";
import { deleteSite, getAllSites, setSiteStatus } from "../../lib/api";
import Table from "../ui/Table";

const tableHeaders = [
  "Sl No",
  "Site Name",
  "Customer Name",
  "Site Status",
  "Actions",
];

const ListSites = ({ checkData, handleCheckData }) => {
  const { sendRequest, status, data: SitesData } = useHttp(getAllSites);

  useEffect(() => {
    sendRequest();
    if (checkData) {
      sendRequest();
      handleCheckData(false);
    }
  }, [sendRequest, checkData, handleCheckData]);

  const {
    sendRequest: updateSiteStatusRequest,
  } = useHttp(setSiteStatus);

  const statusChangeHandler = (id, event) => {
    if (window.confirm("Toggle Site Status?")) {
      const siteData = { id: id, status: event.target.checked };
      updateSiteStatusRequest(siteData);
    } else {
      event.target.checked = !event.target.checked;
    }
  };

  const {
    sendRequest: deleteSiteRequest,
  } = useHttp(deleteSite);

  const deleteClickHandler = (id, event) => {
    if (window.confirm("Delete Site?")) {
      event.preventDefault();
      console.log(id);
      deleteSiteRequest(id);
    }
    handleCheckData(true);
  };

  if (status === "completed") {
    return (
      <div>
        <Table
          tableHeaders={tableHeaders}
          data={SitesData? SitesData :[]}
          onStatusChange={statusChangeHandler}
          onDelete={deleteClickHandler}
        />
      </div>
    );
  }
};
export default ListSites;
