import React, { Fragment, useState, useEffect, useRef } from "react";
import useHttp from "../../hooks/use-http";
import { addNewSite, getActiveCustomers } from "../../lib/api";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

const AddNewSite = ({show, handleCheckData, handleClose}) => {
  const [saveClicked, setSaveClicked] = useState(false);
  const [customerID, setCustomerID] = useState("");
  const [siteName, setSiteName] = useState("");
  const [siteStatus, setSiteStatus] = useState(false);

  const [customerIDError, setCustomerIDError] = useState(false);
  const [siteNameError, setSiteNameError] = useState(false);

  const [customerList, setCustomerList] = useState([
    <option value="select" key="select">
      Select Customer
    </option>,
  ]);

  const target = useRef(null);

  const renderSiteNameErrorTooltip = (props) => (
    <Tooltip id="site-name-tooltip" {...props}>
      Enter Site Name!
    </Tooltip>
  );

  const renderCustomerIDErrorTooltip = (props) => (
    <Tooltip id="customerid-tooltip" {...props}>
      Select Customer!
    </Tooltip>
  );

  const {
    sendRequest: getAllCustomersRequest,
    status,
    data: customerData,
    error,
  } = useHttp(getActiveCustomers);

  const {
    sendRequest: addSite,
    status: statusSite,
    data: addSiteData,
    erro: addSiteError,
  } = useHttp(addNewSite);

  useEffect(() => {
    getAllCustomersRequest();
  }, [getAllCustomersRequest]);

  const siteNameChangeHandler = (event) => {
    setSiteName(event.target.value);
    setSiteNameError(false);
    setSaveClicked(false);
  };

  const siteStatusChangeHanlder = (event) => {
    setSiteStatus(event.target.checked);
    setSaveClicked(false);
  };

  const customerChangeHandler = (event) => {
    setCustomerID(event.target.value);
    setCustomerIDError(false);
    setSaveClicked(false);
  };

  const addSiteClickHandler = (event) => {
    event.preventDefault();
    if (!siteName || siteName.trim() === "" || siteName.trim().length === 0) {
      setSiteNameError(true);
      setSaveClicked(false);
    }
    if (customerID === "select" || customerID.trim() === "") {
      setCustomerIDError(true);
      setSaveClicked(false);
    }
    setSaveClicked(true);
  };

  useEffect(() => {
    if (!siteNameError && !customerIDError && saveClicked) {
      const siteData = {
        customer: customerID,
        site_name: siteName,
        site_status: siteStatus ? siteStatus : false,
      };
      addSite(siteData);
      setSaveClicked(false);
      if (!addSiteError) {
        handleClose(true);
        handleCheckData(true);
        setSiteName("");
        setSiteStatus("");
      }
    }
  }, [
    addSite,
    addSiteError,
    customerID,
    customerIDError,
    handleClose,
    handleCheckData,
    siteName,
    siteNameError,
    siteStatus,
    saveClicked,
  ]);

  const cancelClickHandler = (event) => {
    event.preventDefault();
    handleClose(true);
    handleCheckData(true);
  };

  useEffect(() => {
    if (customerData) {
      setCustomerList((prevState) => [
        [
          <option value="select" key="select">
            Select Customer
          </option>,
        ],
        customerData.map((item, index) => {
          return (
            <option value={item.id} key={index}>
              {item.customer_name}
            </option>
          );
        }),
      ]);
    } else {
      setCustomerList([
        <option value="select" key="select">
          Select Customer
        </option>,
      ]);
    }
  }, [customerData, setCustomerList]);

  return (
    <Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Site</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form action="" method="post">
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="selectCustomer">Select Customer</Form.Label>
              <Col sm={10}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={renderCustomerIDErrorTooltip}
                  target={target.current}
                  show={customerIDError}
                >
                  <Form.Select
                    name="selectCustomer"
                    id="selectCustomer"
                    onChange={customerChangeHandler}
                  >
                    {customerList}
                  </Form.Select>
                </OverlayTrigger>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="siteName">Site Name</Form.Label>
              <Col sm={10}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={renderSiteNameErrorTooltip}
                  target={target.current}
                  show={siteNameError}
                >
                  <Form.Control
                    type="text"
                    name="siteName"
                    id="sitename"
                    value={siteName}
                    onChange={siteNameChangeHandler}
                  />
                </OverlayTrigger>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label as="legend" column sm={2}>
                Site Active
              </Form.Label>
              <Col sm={10}>
                <Form.Check
                  type="switch"
                  name="siteStatus"
                  id="sitestatus"
                  checked={siteStatus}
                  onChange={siteStatusChangeHanlder}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelClickHandler}>
            Close
          </Button>
          <Button variant="primary" onClick={addSiteClickHandler}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default AddNewSite;
