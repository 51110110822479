import React, { Fragment, useState, useEffect, useCallback } from "react";
import useHttp from "../../hooks/use-http";
import { getExternalHttpAccessBySite, toggleExternalHttpAccess } from "../../lib/api";
import CustomerSiteRouter from "../customerSiteRouter/CustomerSiteRouter";
import TableComponent from "../ui/Table";

const tableHeaders = ["Sl No", "Router Name", "External Http Status"];

const ToggleExternalHttpAccess = () => {
  const [siteID, setSiteID] = useState("");
  const [tableHtml, setTableHtml] = useState(<div></div>);
  console.log(siteID);

  const { sendRequest, data, status, error } = useHttp(
    getExternalHttpAccessBySite
  );
  const {
    sendRequest: externalHttpToggleRequest,
    data: externalHttpToggleData,
    status: externalHttpToggleStatus,
    error: externalHttpToggleError,
  } = useHttp(toggleExternalHttpAccess);


  
   
  const statusChangeHandler = useCallback((id, event) => {
    console.log(id, event.target.checked);
    if (window.confirm("Toggle External Http Status Status?")) {
      console.log(id, event.target.checked);
      const routerData = { id: id, status: event.target.checked };
      externalHttpToggleRequest(routerData)
      console.log("Helo");
    } else {
      console.log("Canceled");
      event.target.checked = !event.target.checked;
    }
  },[externalHttpToggleRequest]);

  useEffect(() => {
    if (siteID) {
      sendRequest(siteID);
    }
  }, [sendRequest, siteID]);

  useEffect(() => {
    if (siteID) {
      setTableHtml(
        <TableComponent
          tableHeaders={tableHeaders}
          data={data ? data : []}
          onStatusChange={statusChangeHandler}
          listOnly ={true}
        />
      );
    }
  }, [siteID, data, statusChangeHandler]);

  console.log(data);

  return (
    <Fragment>
      <CustomerSiteRouter setSiteID={setSiteID} hideRouter={true} />
      {tableHtml}
    </Fragment>
  );
};

export default ToggleExternalHttpAccess;
