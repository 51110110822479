import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Form,
  Col,
  Row,
  Button,
  Toast,
  ToastContainer,
  Spinner,
} from "react-bootstrap";
import useHttp from "../../hooks/use-http";
import { renameRouter } from "../../lib/api";
import CustomerSiteRouter from "../customerSiteRouter/CustomerSiteRouter";

const RouterRename = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  const [routerID, setRouterID] = useState("");
  const [routerNewName, setRouterNewName] = useState("");
  const [showOptions, setShowOptions] = useState(false);

  const { sendRequest, data, status } = useHttp(renameRouter);

  useEffect(() => {
    if (status === "completed") {
      console.log(status);
      console.log(data.message);
      setShowToast(true);
      setToastMessage(data.message);
      setShowOptions(false);
      setRouterNewName("");
      setShowSpinner(false);
      setTimeout(()=>{
        window.location.reload(false);
    },1000);
      
    }
  }, [status, data]);

  const RouterNewNameChangeHanlder = (event) => {
    setRouterNewName(event.target.value);
  };

  const renameRouterClickHandler = (event) => {
    event.preventDefault();
    const routerData = { id: routerID, new_router_name: routerNewName };
    sendRequest(routerData);
    setShowSpinner(true);
  };
  useEffect(() => {
    if (routerID) {
      setShowOptions(true);
    }
  }, [routerID]);

  const cancelClickHandler = (event) => {
    event.preventDefault();
    setShowOptions(false);
  };
  return (
    <Fragment>
      <CustomerSiteRouter setRouterID={setRouterID} />
      <ToastContainer className="p-3" position="top-center">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          bg="success"
          delay={2000}
          autohide
        >
          <Toast.Header closeButton={false}>
            <strong className="me-auto">Rename Router</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
      {showOptions ? (
        <Fragment>
          <Form>
            <Form.Group as={Row} className="mb-3" controlId="formRouterNewName">
              <Form.Label as="legend" column sm={2}>
                Router New Name
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="routerNewName"
                  checked={routerNewName}
                  onChange={RouterNewNameChangeHanlder}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Col sm={{ span: 10, offset: 2 }}>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={renameRouterClickHandler}
                >
                  Rename
                </Button>{" "}
                <Button type="cancel" onClick={cancelClickHandler}>
                  Cancel
                </Button>
                {"            "}
                {showSpinner ? <Spinner animation="grow" /> : <></>}
              </Col>
            </Form.Group>
          </Form>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default RouterRename;
