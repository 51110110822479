import React from "react";
import { useState, useContext } from "react";
import styles from "./NewSideBar.module.css";
import cn from "classnames";
import AuthContext from "../../store/auth-context";
import { Link } from "react-router-dom";

const NewSideBar = () => {
  const authCtx = useContext(AuthContext);

  const [showFeature, setShowFeature] = useState({
    setup: false,
    actions: false,
    logAnalyzer: false,
    activityLogs: false,
  });
  const expandClickHandler = (name, event) => {
    event.preventDefault();
    console.log(name);
    if (name === "setup") {
      setShowFeature((prevState) => ({
        setup: !prevState.setup,
        actions: false,
        logAnalyzer: false,
        activityLogs: false,
      }));
    } else if (name === "actions") {
      setShowFeature((prevState) => ({
        setup: false,
        actions: !prevState.actions,
        logAnalyzer: false,
        activityLogs: false,
      }));
    } else if (name === "logAnalyzer") {
      setShowFeature((prevState) => ({
        setup: false,
        actions: false,
        logAnalyzer: !prevState.logAnalyzer,
        activityLogs: false,
      }));
    } else if (name === "activityLogs") {
      setShowFeature((prevState) => ({
        setup: false,
        actions: false,
        logAnalyzer: false,
        activityLogs: !prevState.activityLogs,
      }));
    }
  };
  const logoutClickHandler = (event) => {
    event.preventDefault();
    authCtx.logout();
  };
  return (
    <nav className={styles["sidebar"]}>
      <div className={styles["text"]}>
        <i className={cn("fa-solid fa-globe", styles["logo"])}></i>
        {"        "}Erasmus
      </div>
      <ul style={{ padding: "0px" }}>
        <li>
          <a href="/">
            <i className="fa-solid fa-house"></i>
            {"        "}Home
          </a>
        </li>
        <li>
          <a
            onClick={expandClickHandler.bind(this, "setup")}
            href="/"
            className="featbtn"
          >
            <i className="fa-solid fa-cubes"></i>
            {"        "}
            Setup
            <span
              className={cn(
                "fas fa-caret-down first",
                showFeature.setup ? styles["rotate"] : ""
              )}
            ></span>
          </a>
          <ul
            className={cn(showFeature.setup ? styles["showMenu"] : "")}
            style={{ padding: "0px" }}
          >
            <li>
              <Link to="/customer">Customers</Link>
            </li>
            <li>
              <Link to="/site">Sites</Link>
            </li>
            <li>
              <Link to="/router">Routers</Link>
            </li>
            <li>
              <Link to="/router/event_extraction">Configure Event Extraciton</Link>
            </li>
            <li>
              <Link to="/scheduler">Scheduler</Link>
            </li>
          </ul>
        </li>
        <li>
          <a
            onClick={expandClickHandler.bind(this, "actions")}
            href="/"
            className="featbtn"
          >
            <i className="fa-solid fa-droplet"></i>
            {"        "}
            Actions
            <span
              className={cn(
                "fas fa-caret-down first",
                showFeature.actions ? styles["rotate"] : ""
              )}
            ></span>
          </a>
          <ul
            className={cn(showFeature.actions ? styles["showMenu"] : "")}
            style={{ padding: "0px" }}
          >
            <li>
              <Link to="/router/overview">Router Overview</Link>
            </li>
            <li>
              <Link to="/router/reveal_password">Reveal Password</Link>
            </li>
            <li>
              <Link to="/manage_ports">Manage Ports</Link>
            </li>
            <li>
              <Link to="/router/rename">Router Rename</Link>
            </li>
            <li>
              <Link to="/router/external_http_access">External Http Access</Link>
            </li>
            <li>
              <Link to="/router/event_extraction/manual_trigger">
                Event Extraction
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <a
            onClick={expandClickHandler.bind(this, "logAnalyzer")}
            href="/"
            className="serv-btn"
          >
            <i className="fa-solid fa-chart-simple"></i>
            {"        "}
            Log Analyzer
            <span
              className={cn(
                "fas fa-caret-down first",
                showFeature.logAnalyzer ? styles["rotate"] : ""
              )}
            ></span>
          </a>
          <ul
            className={cn(showFeature.logAnalyzer ? styles["showMenu"] : "")}
            style={{ padding: "0px" }}
          >
            <li>
              <Link to="/log_analyzer">Log Analyzers</Link>
            </li>
            <li>
              <Link to="/log_analyzer/analyzed_logs">View Analyzed Logs</Link>
            </li>
          </ul>
        </li>
        <li>
          <a
            onClick={expandClickHandler.bind(this, "activityLogs")}
            href="/"
            className="serv-btn"
          >
            <i className="fa-solid fa-file-lines"></i>
            {"        "}
            Activity Logs
            <span
              className={cn(
                "fas fa-caret-down first",
                showFeature.activityLogs ? styles["rotate"] : ""
              )}
            ></span>
          </a>
          <ul
            className={cn(showFeature.activityLogs ? styles["showMenu"] : "")}
            style={{ padding: "0px" }}
          >
            <li>
              <Link to="/router/overview_log">Router Overview Logs</Link>
            </li>
            <li>
              <Link to="/router/reveal_password_log">Reveal Password Logs</Link>
            </li>
            <li>
              <Link to="/router/external_http_access/log">
                External Http Access Logs
              </Link>
            </li>
            <li>
              <Link to="/router/password_reset/log">Password Reset Logs</Link>
            </li>
            <li>
              <Link to="/router/event_extraction/log">Event Extraction Logs</Link>
            </li>
            <li>
              <Link to="/router/connection/log">Connection Logs</Link>
            </li>
            <li>
              <Link to="/cron/log">Cron Logs</Link>
            </li>
            <li>
              <Link to="/scheduler/log">Scheduler Run Logs</Link>
            </li>
          </ul>
        </li>
        <li>
          <a href="/" onClick={logoutClickHandler}>
            {" "}
            <i className="fa fa-sign-out"></i>
            {"        "}Logout
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default NewSideBar;
