import React from "react";
import { useEffect } from "react";
import useHttp from "../../hooks/use-http";
import { getLogAnalyzer } from "../../lib/api";
import TableComponent from "../ui/Table";

const tableHeaders = [
  "Sl No",
  "Customer",
  "Site",
  "Router",
  "Analyzer Name",
  "Analyzer Type",
  "Keywords",
  "Description",
  "Last Run",
  "Email",
  "Teams Channel",
  "Status",
  "Action",
];

const ListLogAnalyzer = ({ checkData, handleCheckData }) => {
  const { sendRequest: getAllLogAnalyzer, data: LogAnalyzerData } =
    useHttp(getLogAnalyzer);

  useEffect(() => {
    getAllLogAnalyzer();
    if (checkData) {
        getAllLogAnalyzer();
      handleCheckData(false);
    }
  }, [checkData, getAllLogAnalyzer, handleCheckData]);

  const deleteClickHandler=(id, event)=>{
    event.preventDefault();
    console.log(id);
  }
  const statusChangeHandler = (id) =>{
    console.log(id);
  }
  return (
    <TableComponent
      tableHeaders={tableHeaders}
      data={LogAnalyzerData ? LogAnalyzerData : []}
      onDelete={deleteClickHandler}
      onStatusChange ={statusChangeHandler}
    />
  );
};

export default ListLogAnalyzer;
