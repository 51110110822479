import React from "react";
import { Accordion } from "react-bootstrap";
import CustomerSiteRouter from "./CustomerSiteRouter";

const FilterCustomerSiteRouter = ({setRouterID}) => {
  return (
    <Accordion>
      <Accordion.Item eventKey="0" >
        <Accordion.Header>Filter</Accordion.Header>
        <Accordion.Body>
          <CustomerSiteRouter setRouterID ={setRouterID} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default FilterCustomerSiteRouter;
