import React from 'react'
import {Card} from 'react-bootstrap'
import ShowConnectionLogs from '../components/connetionLog/ShowConnectionLogs'

const ConnectionLog = () => {
  return (
    <Card>
        <Card.Header as="h5">Connection Logs</Card.Header>
        <Card.Body>
            <ShowConnectionLogs/>
    </Card.Body>
    </Card>
  )
}

export default ConnectionLog