import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import UserLogin from "../components/user/UserLogin";
import { useHistory } from "react-router-dom";


const Login = (props) => {
  const history = useHistory();

  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);

  };
  const handleShow = () => setShow(true);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Erasmus - Login
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UserLogin
          handleClose={handleClose}
        />
      </Modal.Body>
    </Modal>
  );
};

export default Login;
