import React,{useEffect} from 'react'
import useHttp from '../../hooks/use-http'
import { getCRONLog } from '../../lib/api'
import TableComponent from '../ui/Table'

const tableHeaders = ["Sl No", "Details", "Run Date-Time"]

const ShowCronLogs = () => {
    const { sendRequest, data, status, error } = useHttp(getCRONLog)
    
      useEffect(() => {
        sendRequest();
      }, [sendRequest]);
  return (
    <TableComponent tableHeaders={tableHeaders} data = {data?data:[]} listOnly={true}/>
  )
}

export default ShowCronLogs