import React, { Fragment } from "react";
import TableComponent from "../ui/Table";
import FilterCustomerSiteRouter from "../customerSiteRouter/FilterCustomerSiteRouter";

const tableHeaders = [
  "Sl No",
  "Customer",
  "Site",
  "Router",
  "File",
  "Source",
  "Logged Date Time",
  "Log Analyzed Date Time",
  "Event Type : Message",
];

const ShowAnalyzedLogs = () => {
  return (
    <Fragment>
    <FilterCustomerSiteRouter/>
    <TableComponent tableHeaders={tableHeaders} data ={[]}/>
    </Fragment>
  )
};

export default ShowAnalyzedLogs;
