import React,{useContext} from 'react'
import AuthContext from '../store/auth-context'


const Logout = () => {
    const authCtx = useContext(AuthContext);
    authCtx.logout()
  return (
    <div>Logout</div>
  )
}

export default Logout