import React, { Fragment, useState } from "react";
import useHttp from "../../hooks/use-http";
import { addNewCustomer } from "../../lib/api";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

const AddCustomer = (props) => {
  const [customerName, setCustomerName] = useState("");
  const [customerStatus, setCustomerStatus] = useState(false);
  const history = useHistory();

  const {
    sendRequest,
    data: customerData,
    status,
    error,
  } = useHttp(addNewCustomer);

  const customerNameChangeHandler = (event) => {
    setCustomerName(event.target.value);
  };

  const customerStatusChangeHanlder = (event) => {
    setCustomerStatus(event.target.checked);
  };

  const addCustomerClickHandler = (event) => {
    event.preventDefault();
    const newCustomerdata = {
      customer_name: customerName,
      customer_status: customerStatus,
    };
    sendRequest(newCustomerdata);
    console.log(error);
    if (!error) {
      history.push("/customer");
    }
  };

  const cancelClickHandler = (event) => {
    event.preventDefault();
    history.push("/customer");
  };
  return (
    <Fragment>
      <Card>
        <Card.Header as="h5">Add New Customer</Card.Header>
        <Card.Body>
          <Form action="" method="post">
            <Form.Group as={Row} className="mb-3" controlId="formCustomerName">
              <Form.Label column sm={2}>
                Customer Name
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="customerName"
                  value={customerName}
                  onChange={customerNameChangeHandler}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formCustomerStatus"
            >
              <Form.Label as="legend" column sm={2}>
                Customer Active
              </Form.Label>
              <Col sm={10}>
                <Form.Check
                  type="switch"
                  name="customerStatus"
                  checked={customerStatus}
                  onChange={customerStatusChangeHanlder}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Col sm={{ span: 10, offset: 2 }}>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={addCustomerClickHandler}
                >
                  Add
                </Button>{" "}
                <Button type="cancel" onClick={cancelClickHandler}>
                  Cancel
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default AddCustomer;
