import React from 'react'
import { Card } from 'react-bootstrap'
import ToggleExternalHttpAccess from '../components/externalHttpAccess/ToggleExternalHttpAccess'

const ExternalHttpAccess = () => {
  return (
    <Card>
        <Card.Header as="h5">Toggle External Http Access</Card.Header>
        <Card.Body>
            <ToggleExternalHttpAccess/>
        </Card.Body>
    </Card>
  )
}

export default ExternalHttpAccess