import React, { useEffect } from "react";
import useHttp from "../../hooks/use-http";
import { getRevealPasswordLog } from "../../lib/api";
import TableComponent from "../ui/Table";

const tableHeaders = [
  "Sl No",
  "User ID",
  "Router Name",
  "Local IP Address",
  "Accessed Date-time",
  "Password Reset Required",
];

const ListRevealPasswordLog = () => {
  const { sendRequest, data } = useHttp(getRevealPasswordLog);

  useEffect(() => {
    sendRequest();
  }, [sendRequest]);

  return (
    <TableComponent
      tableHeaders={tableHeaders}
      data={data ? data : []}
      listOnly={true}
    />
  );
};

export default ListRevealPasswordLog;
