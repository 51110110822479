import React from 'react'
import style from "./Home.module.css"

const Home = () => {
  return (
    <div >
    <h3 style={style.h3}>Welcome to Erasmus</h3>
    <p style={style.p}> 
    The purpose of this application is to provide a clean interface 
    that will allow easy interaction with routers installed on site 
    as well as to heighten access security. All actions completed on 
    the router are logged with what configuration has been completed, 
    when and by which user. Any successful or unsuccessful router 
    connection attempts are recorded to keep track of any possible 
    security breaches. All activity logs are updated daily to ensure 
    maximum visibility. 
    Designed and built by Ramnath Kini, Naveen Prabhu, Spencer Land 
    and Bronte Jurgens.</p>
</div>
  )
}

export default Home