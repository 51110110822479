import React from 'react'
import ListRevealPasswordLog from '../components/revealPassword/ListRevealPasswordLog'
import {Card} from 'react-bootstrap'
const RevealPasswordLog = () => {
  return (
    <Card>
        <Card.Header as="h5">Reveal Password Logs</Card.Header>
        <Card.Body>
    <ListRevealPasswordLog />
    </Card.Body>
    </Card>
  )
}

export default RevealPasswordLog