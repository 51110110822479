import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ListEventExtractionConfiguration from "../components/eventExtraction/ListEventExtractionConfiguration";
import AddNewEventConfiguration from "../components/eventExtraction/AddNewEventConfiguration";

const EventExtractionConfiguration = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [checkData, setCheckData] = useState(false);

  return (
    <Card>
      <Card.Header>
        <h4 style={{ display: "inline" }}>Event Extraction</h4>
        <Button
          style={{ float: "right" }}
          variant="primary"
          onClick={handleShow}
        >
          Add New Event
        </Button>
      </Card.Header>
      <Card.Body>
        <ListEventExtractionConfiguration checkData={checkData} handleCheckData={setCheckData}  />
        <AddNewEventConfiguration
          show={show}
          handleClose={handleClose}
          handleCheckData={setCheckData}
        />
      </Card.Body>
    </Card>
  );
};

export default EventExtractionConfiguration;
