import React from "react";
import { useState } from "react";
import { Card, Button } from "react-bootstrap";
import AddScheduler from "../components/scheduler/AddScheduler";
import ListScheduler from "../components/scheduler/ListScheduler";

const Scheduler = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [checkData, setCheckData] = useState(false);


  return (
    <Card>
      <Card.Header>
        <h4 style={{ display: "inline" }}>Scheduler</h4>
        <Button style={{ float: "right" }} variant="primary" onClick={handleShow}>
        Add New Scheduler
      </Button>
      </Card.Header>
      <Card.Body>
        <ListScheduler checkData={checkData} handleCheckData={setCheckData} />
        <AddScheduler show={show} handleClose={handleClose} handleCheckData={setCheckData}/>
      </Card.Body>
    </Card>
  );
};

export default Scheduler;
