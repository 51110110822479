import React, { Fragment, useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { Modal, Col, Row, Button } from "react-bootstrap";
import useHttp from "../../hooks/use-http";
import {
  getActiveCustomers,
  getSiteByCustomer,
  getRouterBySite,
  addEventExtractionConfiguration,
} from "../../lib/api";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const AddNewEventConfiguration = ({ show, handleCheckData, handleClose }) => {
  const [customerList, setCustomerList] = useState([
    <option value="select" key="select">
      Select Customer
    </option>,
  ]);

  const [siteList, setSiteList] = useState([
    <option value="select" key="select">
      Select Site
    </option>,
  ]);

  const [routerList, setRouterList] = useState([
    <option value="select" key="select">
      Select Router
    </option>,
  ]);

  const [customerID, setCustomerID] = useState("");
  const [siteID, setSiteID] = useState("");
  const [routerID, setRouterID] = useState("");
  const [eventType, setEventType] = useState("");

  const [saveClicked, setSaveClicked] = useState(false);
  const [customerIDError, setCustomerIDError] = useState(false);
  const [siteIDError, setSiteIDError] = useState(false);
  const [routerIDError, setRouterIDError] = useState(false);
  const [eventTypeError, setEventTypeError] = useState(false);

  const target = useRef(null);

  const renderCustomerIDErrorTooltip = (props) => (
    <Tooltip id="customerid-tooltip" {...props}>
      Select Customer!
    </Tooltip>
  );
  const renderSiteIdErrorTooltip = (props) => (
    <Tooltip id="siteid-tooltip" {...props}>
      Select Site!
    </Tooltip>
  );
  const renderRouterIdErrorTooltip = (props) => (
    <Tooltip id="routerid-tooltip" {...props}>
      Select Router!
    </Tooltip>
  );
  const renderEventTypeErrorTooltip = (props) => (
    <Tooltip id="event-type-tooltip" {...props}>
      Enter Event Type!
    </Tooltip>
  );

  const {
    sendRequest: getAllCustomersRequest,
    status,
    data: customerData,
    error,
  } = useHttp(getActiveCustomers);

  useEffect(() => {
    getAllCustomersRequest();
  }, [getAllCustomersRequest]);

  const {
    sendRequest: siteListByCustomerRequest,
    status: siteStatus,
    data: siteByCustomerData,
    error: siteError,
  } = useHttp(getSiteByCustomer);

  useEffect(() => {
    if (customerID) {
      siteListByCustomerRequest(customerID);
    } else {
      return;
    }
  }, [siteListByCustomerRequest, customerID]);

  useEffect(() => {
    if (siteByCustomerData) {
      setSiteList((prevstate) => [
        ...prevstate,
        siteByCustomerData.map((item, index) => {
          return (
            <option value={item.site_id} key={index}>
              {item.site_name}
            </option>
          );
        }),
      ]);
    } else {
      setSiteList([
        <option value="select" key="select">
          Select Site
        </option>,
      ]);
    }
  }, [setSiteList, siteByCustomerData]);

  const {
    sendRequest: routerListBySiteRequest,
    status: routerStatus,
    data: routerBySiteData,
    error: routerError,
  } = useHttp(getRouterBySite);

  useEffect(() => {
    if (routerBySiteData) {
      setRouterList((prevstate) => [
        ...prevstate,
        routerBySiteData.map((item, index) => {
          return (
            <option value={item.router_id} key={index}>
              {item.router_name}
            </option>
          );
        }),
      ]);
    } else {
        setRouterList([
        <option value="select" key="select">
          Select Router
        </option>,
      ]);
    }
  }, [setRouterList, routerBySiteData]);

  useEffect(() => {
    if (siteID) {
      routerListBySiteRequest(siteID);
    } else {
      return;
    }
  }, [routerListBySiteRequest, siteID]);

  const getCustomerList = (event) => {
    setCustomerList((prevState) => [
      [
        <option value="select" key="select">
          Select Customer
        </option>,
      ],
      customerData.map((item, index) => {
        return (
          <option value={item.id} key={index}>
            {item.customer_name}
          </option>
        );
      }),
    ]);
  };

  const {
    sendRequest: addEventExtractionConfigRequest,
    data: addEventExtractionConfigData,
    status: addEventExtractionConfigStatus,
    error: addEventExtractionConfigError,
  } = useHttp(addEventExtractionConfiguration);

  const addEventExtractionConfigClickHandler = (event) => {
    event.preventDefault();
    if (
      !eventType ||
      eventType.trim() === "" ||
      eventType.trim().length === 0
    ) {
      setEventTypeError(true);
      setSaveClicked(false);
    }
    if (customerID === "select" || customerID.trim() === "") {
      setCustomerIDError(true);
      setSaveClicked(false);
    }
    if (siteID === "select" || siteID.trim() === "") {
      setSiteIDError(true);
      setSaveClicked(false);
    }
    if (routerID === "select" || routerID.trim() === "") {
      setRouterIDError(true);
      setSaveClicked(false);
    }
    setSaveClicked(true);
  };

  useEffect(() => {
    if (
      !siteIDError &&
      !routerIDError &&
      !customerIDError &&
      !eventTypeError &&
      saveClicked
    ) {
      const routerData = {
        customer: customerID,
        site: siteID,
        router: routerID,
        event_type: eventType,
      };
      addEventExtractionConfigRequest(routerData);
      setSaveClicked(false);

      if (!addEventExtractionConfigError) {
        handleClose(true);
        handleCheckData(true);
        setEventType("");
      }
    }
  }, [
    addEventExtractionConfigError,
    addEventExtractionConfigRequest,
    customerID,
    customerIDError,
    eventType,
    eventTypeError,
    handleCheckData,
    handleClose,
    routerID,
    routerIDError,
    saveClicked,
    siteID,
    siteIDError,
  ]);

  const cancelClickHandler = (event) => {
    event.preventDefault();
    handleClose(true);
    handleCheckData(true);
  };
  return (
    <Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form action="" method="post">
            <Form.Group as={Row} className="mb-3" controlId="selectCustomer">
              <Form.Label>Select Customer</Form.Label>
              <Col sm={10}>
              <OverlayTrigger
                  placement="bottom"
                  overlay={renderCustomerIDErrorTooltip}
                  target={target.current}
                  show={customerIDError}
                >
                <Form.Select
                  name="selectCustomer"
                  onClick={getCustomerList}
                  onChange={(event) => {
                    setCustomerID(event.target.value);
                    setCustomerIDError(false);
                    setSaveClicked(false);
                  }}
                >
                  {customerList}
                </Form.Select>
                </OverlayTrigger>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formSite">
              <Form.Label>Select Site</Form.Label>
              <Col sm={10}>
              <OverlayTrigger
                  placement="bottom"
                  overlay={renderSiteIdErrorTooltip}
                  target={target.current}
                  show={siteIDError}
                >
                <Form.Select
                  name="selectSite"
                  onChange={(event) => {
                    setSiteID(event.target.value);
                    setSiteIDError(false);
                    setSaveClicked(false);
                  }}
                >
                  {siteList}
                </Form.Select>
                </OverlayTrigger>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formSelectRouter">
              <Form.Label>Select Router</Form.Label>
              <Col sm={10}>
              <OverlayTrigger
                  placement="bottom"
                  overlay={renderRouterIdErrorTooltip}
                  target={target.current}
                  show={routerIDError}
                >
                <Form.Select
                  name="selectRouter"
                  onChange={(event) => {
                    setRouterID(event.target.value);
                    setRouterIDError(false);
                    setSaveClicked(false);
                  }}
                >
                  {routerList}
                </Form.Select>
                </OverlayTrigger>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formEventType">
              <Form.Label>Event Type</Form.Label>
              <Col sm={10}>
              <OverlayTrigger
                  placement="bottom"
                  overlay={renderEventTypeErrorTooltip}
                  target={target.current}
                  show={eventTypeError}
                >
                <Form.Control
                  type="text"
                  name="eventType"
                  value={eventType}
                  onChange={(event) => {
                    setEventType(event.target.value);
                    setEventTypeError(false);
                    setSaveClicked(false);
                  }}
                />
                </OverlayTrigger>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelClickHandler}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={addEventExtractionConfigClickHandler}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default AddNewEventConfiguration;
