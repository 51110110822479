import React,{useEffect, useState} from 'react'
import ListAddRouterPorts from '../components/routerPorts/ListAddRouterPorts'
import Card from "react-bootstrap/Card";
import ConfigRouterPorts from '../components/routerPorts/ConfigRouterPorts';


const RouterPorts = () => {
    const [routerPortID, setRouterPortID] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (id) => {
       
        setShow(true);

    };
   
  return (
    <Card>
      <Card.Header as="h5">Manage Router Ports</Card.Header>
      <Card.Body>
      <ListAddRouterPorts show={handleShow} setRouterPortID = {setRouterPortID}/>
      <ConfigRouterPorts show={show} routerPortID={routerPortID} handleClose={handleClose}/>
      </Card.Body>
      </Card>
    
    
  )
}

export default RouterPorts