import React, { Fragment, useState, useEffect, useRef } from "react";
import useHttp from "../../hooks/use-http";
import {
  addNewRouter,
  getActiveCustomers,
  getSiteByCustomer,
} from "../../lib/api";

import { Modal, Row, Col, Form, Button,  OverlayTrigger, Tooltip } from "react-bootstrap";

const AddNewRouter = React.memo(({ show, handleCheckData, handleClose }) => {
  const [customerList, setCustomerList] = useState([
    <option value="select" key="select">
      Select Customer
    </option>,
  ]);

  const [siteList, setSiteList] = useState([
    <option value="select" key="select">
      Select Site
    </option>,
  ]);

  const [customerID, setCustomerID] = useState("");
  const [siteID, setSiteID] = useState("");
  const [routerName, setRouterName] = useState("");
  const [routerAccessId, setRouterAccessId] = useState("");
  const [routerAccessPassword, setRouterAccessPassword] = useState("");
  const [routerAccessURL, setRouterAccessURL] = useState("");
  const [routerBrand, setRouterBrand] = useState("");
  const [routerFirmware, setRouterFirmware] = useState("");
  const [routerConnectionAttempts, setRouterConnectionAttempts] = useState("3");
  const [routerStatus, setRouterStatus] = useState(false);

  const [saveClicked, setSaveClicked] = useState(false);
  const [customerIDError, setCustomerIDError] = useState(false);
  const [siteIDError, setSiteIDError] = useState(false);
  const [routerNameError, setRouterNameError] = useState(false);
  const [routerAccessIdError, setRouterAccessIdError] = useState(false);
  const [routerAccessPasswordError, setRouterAccessPasswordError] =
    useState(false);
  const [routerAccessURLError, setRouterAccessURLError] = useState(false);
  const [routerBrandError, setRouterBrandError] = useState(false);
  const [routerFirmwareError, setRouterFirmwareError] = useState(false);
  const [routerConnectionAttemptsError, setRouterConnectionAttemptsError] =
    useState(false);

  const target = useRef(null);

  const renderCustomerIDErrorTooltip = (props) => (
    <Tooltip id="customerid-tooltip" {...props}>
      Select Customer!
    </Tooltip>
  );
  const renderSiteIdErrorTooltip = (props) => (
    <Tooltip id="siteid-tooltip" {...props}>
      Select Site!
    </Tooltip>
  );
  const renderRouterNameErrorTooltip = (props) => (
    <Tooltip id="routerNameError-tooltip" {...props}>
      Enter Router Name!
    </Tooltip>
  );
  const renderRouterAccessIdErrorTooltip = (props) => (
    <Tooltip id="routerAccessIdError-tooltip" {...props}>
      Enter Router Access ID!
    </Tooltip>
  );
  const renderRouterAccessPasswordErrorTooltip = (props) => (
    <Tooltip id="routerAccessPasswordError-tooltip" {...props}>
      Enter Router Access Password
    </Tooltip>
  );
  const renderRouterAccessURLErrorTooltip = (props) => (
    <Tooltip id="routerAccessURLError-tooltip" {...props}>
      Select Site!
    </Tooltip>
  );
  const renderRouterBrandErrorTooltip = (props) => (
    <Tooltip id="routerBrandError-tooltip" {...props}>
      Select Site!
    </Tooltip>
  );
  const renderRouterFirmwareErrorTooltip = (props) => (
    <Tooltip id="routerFirmwareError-tooltip" {...props}>
      Select Site!
    </Tooltip>
  );
  const renderRouterConnectionAttemptsErrorTooltip = (props) => (
    <Tooltip id="routerConnectionAttemptsError-tooltip" {...props}>
      Select Site!
    </Tooltip>
  );

  const {
    sendRequest: getAllCustomersRequest,
    status,
    data: customerData,
    error,
  } = useHttp(getActiveCustomers);

  useEffect(() => {
    getAllCustomersRequest();
  }, [getAllCustomersRequest]);

  const {
    sendRequest: siteListByCustomerRequest,
    status: siteStatus,
    data: siteByCustomerData,
    error: siteError,
  } = useHttp(getSiteByCustomer);

  useEffect(() => {
    if (customerID) {
      siteListByCustomerRequest(customerID);
    } else {
      return;
    }
  }, [siteListByCustomerRequest, customerID]);

  useEffect(() => {
    if (siteByCustomerData) {
      setSiteList((prevstate) => [
        ...prevstate,
        siteByCustomerData.map((item, index) => {
          return (
            <option value={item.site_id} key={index}>
              {item.site_name}
            </option>
          );
        }),
      ]);
    } else {
      setSiteList([
        <option value="select" key="select">
          Select Site
        </option>,
      ]);
    }
  }, [setSiteList, siteByCustomerData]);

  useEffect(() => {
    if (customerData) {
      setCustomerList((prevState) => [
        [
          <option value="select" key="select">
            Select Customer
          </option>,
        ],
        customerData.map((item, index) => {
          return (
            <option value={item.id} key={index}>
              {item.customer_name}
            </option>
          );
        }),
      ]);
    } else {
      setCustomerList([
        <option value="select" key="select">
          Select Customer
        </option>,
      ]);
    }
  }, [customerData, setCustomerList]);

  const {
    sendRequest: addNewRouterRequest,
    data: addNewRouterData,
    status: addNewRouterStatus,
    error: addNewRouterError,
  } = useHttp(addNewRouter);

  const addRouterClickHandler = (event) => {
    event.preventDefault();
    if (
      !routerName ||
      routerName.trim() === "" ||
      routerName.trim().length === 0
    ) {
      setRouterNameError(true);
      setSaveClicked(false);
    }
    if (
      !routerAccessId ||
      routerAccessId.trim() === "" ||
      routerAccessId.trim().length === 0
    ) {
      setRouterAccessIdError(true);
      setSaveClicked(false);
    }
    if (
      !routerAccessPassword ||
      routerAccessPassword.trim() === "" ||
      routerAccessPassword.trim().length === 0
    ) {
      setRouterAccessPasswordError(true);
      setSaveClicked(false);
    }
    if (
      !routerAccessURL ||
      routerAccessURL.trim() === "" ||
      routerAccessURL.trim().length === 0
    ) {
      setRouterAccessURLError(true);
      setSaveClicked(false);
    }
    if (
      !routerBrand ||
      routerBrand.trim() === "" ||
      routerBrand.trim().length === 0
    ) {
      setRouterBrandError(true);
      setSaveClicked(false);
    }
    if (
      !routerFirmware ||
      routerFirmware.trim() === "" ||
      routerFirmware.trim().length === 0
    ) {
      setRouterFirmwareError(true);
      setSaveClicked(false);
    }
    if (
      !routerConnectionAttempts ||
      routerConnectionAttempts.trim() === "" ||
      routerConnectionAttempts.trim().length === 0
    ) {
      setRouterConnectionAttemptsError(true);
      setSaveClicked(false);
    }
    if (customerID === "select" || customerID.trim() === "") {
      setCustomerIDError(true);
      setSaveClicked(false);
    }
    if (siteID === "select" || siteID.trim() === "") {
      setSiteIDError(true);
      setSaveClicked(false);
    }

    setSaveClicked(true);
  };

  useEffect(() => {
    if (
      !siteIDError &&
      !customerIDError &&
      !routerNameError &&
      !routerAccessIdError &&
      !routerAccessPasswordError &&
      !routerAccessURLError &&
      !routerBrandError &&
      !routerFirmwareError &&
      !routerConnectionAttemptsError &&
      saveClicked
    ) {
      const routerData = {
        customer: customerID,
        site: siteID,
        router_name: routerName,
        access_id: routerAccessId,
        access_pwd: routerAccessPassword,
        access_url: routerAccessURL,
        brand: routerBrand,
        firmware: routerFirmware,
        connection_attempts: routerConnectionAttempts,
        router_status: routerStatus ? routerStatus : false,
      };
      addNewRouterRequest(routerData);
      setSaveClicked(false);
      if (!addNewRouterError) {
        handleClose(true);
        handleCheckData(true);
        setRouterName("");
        setRouterAccessId("");
        setRouterAccessPassword("");
        setRouterAccessURL("");
        setRouterBrand("");
        setRouterFirmware("");
        setRouterConnectionAttempts("3");
        setRouterStatus(false);
      }
    }
  }, [
    addNewRouterError,
    addNewRouterRequest,
    customerID,
    customerIDError,
    handleCheckData,
    handleClose,
    routerAccessId,
    routerAccessIdError,
    routerAccessPassword,
    routerAccessPasswordError,
    routerAccessURL,
    routerAccessURLError,
    routerBrand,
    routerBrandError,
    routerConnectionAttempts,
    routerConnectionAttemptsError,
    routerFirmware,
    routerFirmwareError,
    routerName,
    routerNameError,
    routerStatus,
    saveClicked,
    siteID,
    siteIDError,
  ]);

  const cancelClickHandler = (event) => {
    event.preventDefault();
    handleClose(true);
  };

  return (
    <Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Router</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form action="" method="post">
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="selectCustomer">Select Customer</Form.Label>
              <Col sm={10}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={renderCustomerIDErrorTooltip}
                  target={target.current}
                  show={customerIDError}
                >
                  <Form.Select
                    name="selectCustomer"
                    id="selectCustomer"
                    onChange={(event) => {
                      setCustomerID(event.target.value);
                      setCustomerIDError(false);
                      setSaveClicked(false);
                    }}
                  >
                    {customerList}
                  </Form.Select>
                </OverlayTrigger>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="selectCustomer">Select Site</Form.Label>
              <Col sm={10}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={renderSiteIdErrorTooltip}
                  target={target.current}
                  show={siteIDError}
                >
                  <Form.Select
                    name="selectSite"
                    id="selectSite"
                    onChange={(event) => {
                      setSiteID(event.target.value);
                      setSiteIDError(false);
                      setSaveClicked(false);
                    }}
                  >
                    {siteList}
                  </Form.Select>
                </OverlayTrigger>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="routerName">Router Name</Form.Label>
              <Col sm={10}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={renderRouterNameErrorTooltip}
                  target={target.current}
                  show={routerNameError}
                >
                  <Form.Control
                    type="text"
                    name="routerName"
                    id="routerName"
                    value={routerName}
                    onChange={(event) => {
                      setRouterName(event.target.value);
                      setRouterNameError(false);
                      setSaveClicked(false);
                    }}
                  />
                </OverlayTrigger>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="routerAccessId">Router Access ID</Form.Label>
              <Col sm={10}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={renderRouterAccessIdErrorTooltip}
                  target={target.current}
                  show={routerAccessIdError}
                >
                  <Form.Control
                    type="text"
                    name="routerAccessId"
                    id="routerAccessId"
                    value={routerAccessId}
                    onChange={(event) => {
                      setRouterAccessId(event.target.value);
                      setRouterAccessIdError(false);
                      setSaveClicked(false);
                    }}
                  />
                </OverlayTrigger>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="routerAccessPassword">
                Router Access Password
              </Form.Label>
              <Col sm={10}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={renderRouterAccessPasswordErrorTooltip}
                  target={target.current}
                  show={routerAccessPasswordError}
                >
                  <Form.Control
                    type="text"
                    name="routerAccessPassword"
                    id="routerAccessPassword"
                    value={routerAccessPassword}
                    onChange={(event) => {
                      setRouterAccessPassword(event.target.value);
                      setRouterAccessPasswordError(false);
                      setSaveClicked(false);
                    }}
                  />
                </OverlayTrigger>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="routerAccessURL">
                Router Access URL
              </Form.Label>
              <Col sm={10}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={renderRouterAccessURLErrorTooltip}
                  target={target.current}
                  show={routerAccessURLError}
                >
                  <Form.Control
                    type="text"
                    name="routerAccessURL"
                    id="routerAccessURL"
                    value={routerAccessURL}
                    onChange={(event) => {
                      setRouterAccessURL(event.target.value);
                      setRouterAccessURLError(false);
                      setSaveClicked(false);
                    }}
                  />
                </OverlayTrigger>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="routerBrand">Router Brand</Form.Label>
              <Col sm={10}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={renderRouterBrandErrorTooltip}
                  target={target.current}
                  show={routerBrandError}
                >
                  <Form.Control
                    type="text"
                    name="routerBrand"
                    id="routerBrand"
                    value={routerBrand}
                    onChange={(event) => {
                      setRouterBrand(event.target.value);
                      setRouterBrandError(false);
                      setSaveClicked(false);
                    }}
                  />
                </OverlayTrigger>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="routerFirmware">Router Firmware</Form.Label>
              <Col sm={10}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={renderRouterFirmwareErrorTooltip}
                  target={target.current}
                  show={routerFirmwareError}
                >
                  <Form.Control
                    type="text"
                    name="routerFirmware"
                    id="routerFirmware"
                    value={routerFirmware}
                    onChange={(event) => {
                      setRouterFirmware(event.target.value);
                      setRouterFirmwareError(false);
                      setSaveClicked(false);
                    }}
                  />
                </OverlayTrigger>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="routerConnectionAttempts">
                Router Connection Attempts
              </Form.Label>
              <Col sm={10}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={renderRouterConnectionAttemptsErrorTooltip}
                  target={target.current}
                  show={routerConnectionAttemptsError}
                >
                  <Form.Control
                    type="text"
                    name="routerConnectionAttempts"
                    id="routerConnectionAttempts"
                    value={routerConnectionAttempts}
                    onChange={(event) => {
                      setRouterConnectionAttempts(event.target.value);
                      setRouterConnectionAttemptsError(false);
                      setSaveClicked(false);
                    }}
                  />
                </OverlayTrigger>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label as="legend" column sm={2}>
                Router Active
              </Form.Label>
              <Col sm={10}>
                <Form.Check
                  type="switch"
                  name="routerStatus"
                  id="routerStatus"
                  checked={routerStatus}
                  onChange={(event) => {
                    setRouterStatus(event.target.checked);
                    setSaveClicked(false);
                  }}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelClickHandler}>
            Close
          </Button>
          <Button variant="primary" onClick={addRouterClickHandler}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
});

export default AddNewRouter;
