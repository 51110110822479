import React from 'react'
import { useEffect } from 'react'
import useHttp from '../../hooks/use-http'
import { getRouterOverviewLog } from '../../lib/api'
import TableComponent from '../ui/Table'


const tableHeaders = ["Sl No", "Customer", "Site", "Router", "Firmware", "Router Uptime", "Local Device Time", "RAM Memory Usage", "Flash Memory Usage", "Current Date-time", "Local IP Address"]


const GetRouterOverviewLogs = () => {
    const {sendRequest, data, status, error} = useHttp(getRouterOverviewLog);
    useEffect(()=>{
        sendRequest();
    }, [sendRequest])

  return (
        <TableComponent tableHeaders={tableHeaders} data ={data  ? data :[]} listOnly={true}/>
  )
}

export default GetRouterOverviewLogs