import React, { useEffect } from "react";
import Table from "../ui/Table";
import { deleteCustomer, getAllCustomers, setCustomerStatus } from "../../lib/api";
import useHttp from "../../hooks/use-http";



const tableHeaders = ["Sl No", "Customer Name", "Customer Status", "Actions"];

const ListCustomer = ({checkData, handleCheckData}) => {
  const {
    sendRequest,
    status,
    data: CustomersData,
    error,
  } = useHttp(getAllCustomers);

  useEffect(() => {
    sendRequest();
    if(checkData){
        sendRequest();
        handleCheckData(false)
    }
  }, [sendRequest, checkData,handleCheckData]);

  const {
    sendRequest: updateCustomerStatusRequest,
    status: requestStatus,
    data: CustomerStatusData,
    error: customerStatusError,
  } = useHttp(setCustomerStatus);

  const statusChangeHandler = (id, event) => {
    if(window.confirm("Toggle Customer Status?")){
    const customerData = { id: id, status: event.target.checked };
    updateCustomerStatusRequest(customerData);
    }else{
        event.target.checked = !event.target.checked;
    }

  };


  const {
    sendRequest: deleteCustomerRequest,
    status: deleteCustomerRequestStatus,
    data: deleteCustomerData,
    error: deleteCustomerError,
  } = useHttp(deleteCustomer);

  const deleteClickHandler = (id, event) =>{
    if(window.confirm("Delete Customer?")){
    event.preventDefault();
    console.log(id);
    deleteCustomerRequest(id);
    handleCheckData(true)
  }
}

  if (status === "completed") {
    return (
        <Table
          tableHeaders={tableHeaders}
          data={CustomersData ? CustomersData : []}
          onStatusChange={statusChangeHandler}
          onDelete={deleteClickHandler}
        />
    );
  }
};

export default ListCustomer;
