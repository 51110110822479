import React from 'react'
import { Card } from 'react-bootstrap'
import RouterRename from '../components/routerRename/RouterRename'

const RenameRouter = () => {
  return (
    <Card>
        <Card.Header as="h5">
            Rename Router
        </Card.Header>
        <Card.Body>
            <RouterRename/>
        </Card.Body>
    </Card>

  )
}

export default RenameRouter