import React from 'react'
import { Card } from 'react-bootstrap'
import ShowPassword from '../components/revealPassword/ShowPassword'

const RevealPassword = () => {
  return (
    <Card>
        <Card.Header as="h5">Show Router Password</Card.Header>
        <Card.Body>
    <ShowPassword/>
    
    </Card.Body>
    </Card>

  )
}

export default RevealPassword