import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import useHttp from "../../hooks/use-http";
import { getRouterPortsConfig, setRouterPortsConfig } from "../../lib/api";

const ConfigRouterPorts = ({ show, routerPortID, handleClose }) => {
  const [sourceMacAddress, setSourceMacAddress] = useState("");
  const [sourceIpAddress, setSourceIpAddress] = useState("");
  const [sourcePort, setSourcePort] = useState("");
  const [destinationIpAddress, setDestinationIpAddress] = useState("");
  const [destinationPort, setDestinationPort] = useState("");
  const [externalIpAddress, setExternalIpAddress] = useState("");
  const [externalPort, setExternalPort] = useState("");
  const [saveClicked, setSaveClicked] = useState(false);

  const {
    sendRequest: getPortConfigByPortRequest,
    data: PortConfigByRouterData,
  } = useHttp(getRouterPortsConfig);

  useEffect(() => {
    if (routerPortID) {
      getPortConfigByPortRequest(routerPortID);
    }
  }, [routerPortID, getPortConfigByPortRequest]);


  useEffect(() => {
    if (PortConfigByRouterData && show) {
      setSourceMacAddress(
        PortConfigByRouterData.source_mac_address
          ? PortConfigByRouterData.source_mac_address
          : ""
      );
      setSourceIpAddress(
        PortConfigByRouterData.source_ip_address
          ? PortConfigByRouterData.source_ip_address
          : ""
      );
      setSourcePort(
        PortConfigByRouterData.source_port
          ? PortConfigByRouterData.source_port
          : ""
      );
      setDestinationIpAddress(
        PortConfigByRouterData.destination_ip_address
          ? PortConfigByRouterData.destination_ip_address
          : ""
      );
      setDestinationPort(
        PortConfigByRouterData.destination_port
          ? PortConfigByRouterData.destination_port
          : ""
      );
      setExternalIpAddress(
        PortConfigByRouterData.external_ip_address
          ? PortConfigByRouterData.external_ip_address
          : ""
      );
      setExternalPort(
        PortConfigByRouterData.external_port
          ? PortConfigByRouterData.external_port
          : ""
      );
    } else {
      setSourceMacAddress("");
      setSourceIpAddress("");
      setSourcePort("");
      setDestinationIpAddress("");
      setDestinationPort("");
      setExternalIpAddress("");
      setExternalPort("");
    }
  }, [PortConfigByRouterData, show]);

  const sourceMacAddressChangeHandler = (event) => {
    setSourceMacAddress(event.target.value);
  };

  const sourceIpAddressChangeHandler = (event) => {
    setSourceIpAddress(event.target.value);
  };
  const sourcePortChangeHandler = (event) => {
    setSourcePort(event.target.value);
  };
  const destinationIpAddressChangeHandler = (event) => {
    setDestinationIpAddress(event.target.value);
  };
  const destinationPortChangeHandler = (event) => {
    setDestinationPort(event.target.value);
  };
  const externalIpAddressChangeHandler = (event) => {
    setExternalIpAddress(event.target.value);
  };
  const externalPortChangeHandler = (event) => {
    setExternalPort(event.target.value);
  };

  const {
    sendRequest: setPortConfigByPortRequest,
    data: setPortConfigByRouterData,
  } = useHttp(setRouterPortsConfig);

  useEffect(() => {
    if (saveClicked) {
      const routerPortConfigData = {
        id :routerPortID,
        source_mac_address: sourceMacAddress,
        source_ip_address: sourceIpAddress,
        source_port: sourcePort,
        external_ip_address: externalIpAddress,
        external_port: externalPort,
        destination_ip_address: destinationIpAddress,
        destination_port: destinationPort,
      };
      console.log(routerPortConfigData);
      setPortConfigByPortRequest(routerPortConfigData);
    }
    setSaveClicked(false);
  }, [
    destinationIpAddress,
    destinationPort,
    externalIpAddress,
    externalPort,
    routerPortID,
    saveClicked,
    setPortConfigByPortRequest,
    sourceIpAddress,
    sourceMacAddress,
    sourcePort,
  ]);

  const saveRouterPortConfigClickHandler = () => {
    setSaveClicked(true);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Additional Port Configuration </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group as={Row} className="mb-3">
            <Form.Label htmlFor="sourceMacAddress" column sm={2}>
              Source Mac Address
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="sourceMacAddress"
                id="sourceMacAddress"
                value={sourceMacAddress}
                onChange={sourceMacAddressChangeHandler}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label htmlFor="sourceIpAddress" column sm={2}>
              Source IP Address
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="sourceIpAddress"
                id="sourceIpAddress"
                value={sourceIpAddress}
                onChange={sourceIpAddressChangeHandler}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label htmlFor="sourcePort" column sm={2}>
              Source Port
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="sourcePort"
                id="sourcePort"
                value={sourcePort}
                onChange={sourcePortChangeHandler}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label htmlFor="destinationIpAddress" column sm={2}>
              Destination Ip Address
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="destinationIpAddress"
                id="destinationIpAddress"
                value={destinationIpAddress}
                onChange={destinationIpAddressChangeHandler}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label htmlFor="destinationPort" column sm={2}>
              Destination Port
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="destinationPort"
                id="destinationPort"
                value={destinationPort}
                onChange={destinationPortChangeHandler}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label htmlFor="externalIpAddress" column sm={2}>
              External Ip Address
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="externalIpAddress"
                id="externalIpAddress"
                value={externalIpAddress}
                onChange={externalIpAddressChangeHandler}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label htmlFor="externalPort" column sm={2}>
              External Port
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="externalPort"
                id="externalPort"
                value={externalPort}
                onChange={externalPortChangeHandler}
              />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={saveRouterPortConfigClickHandler}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfigRouterPorts;
