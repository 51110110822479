import React, { useState, useRef } from "react";
import { Form, Row, Col, Modal, Button } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { addNewCustomer } from "../../lib/api";
import useHttp from "../../hooks/use-http";

const AddNewCustomer = (props) => {
  const [customerName, setCustomerName] = useState("");
  const [customerStatus, setCustomerStatus] = useState(false);
  const [customerNameError, setCustomerNameError] = useState(false);

  const target = useRef(null);

  const renderTooltip = (props) => (
    <Tooltip id="customer-name-tooltip" {...props}>
      Enter Customer Name!
    </Tooltip>
  );

  const {
    sendRequest: newCustomerRequest,
    data: customerData,
    status,
    error,
  } = useHttp(addNewCustomer);

  const customerNameChangeHandler = (event) => {
    setCustomerName(event.target.value);
    setCustomerNameError(false);
  };

  const customerStatusChangeHanlder = (event) => {
    setCustomerStatus(event.target.checked);
  };

  const addCustomerClickHandler = (event) => {
    event.preventDefault();
    if (
      !customerName ||
      customerName.trim() === "" ||
      customerName.trim().length === 0
    ) {
      setCustomerNameError(true);
    } else {
      setCustomerNameError(false);
      const newCustomerdata = {
        customer_name: customerName,
        customer_status: customerStatus ? customerStatus : false,
      };
      newCustomerRequest(newCustomerdata);
      console.log(error);
      if (!error) {
        props.handleClose(true);
        props.handleCheckData(true);
        setCustomerName("");
        setCustomerStatus("");
      } else {
        console.log("ERROR");
      }
    }
  };

  const cancelClickHandler = (event) => {
    event.preventDefault();
    props.handleClose(true);
    props.handleCheckData(true);
  };
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add New Analyzer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form action="" method="post">
          <Form.Group as={Row} className="mb-3" controlId="formCustomerName">
            <Form.Label column sm={2}>
              Customer Name
            </Form.Label>
            <Col sm={10}>
              <OverlayTrigger
                placement="bottom"
                overlay={renderTooltip}
                target={target.current}
                show={customerNameError}
              >
                <Form.Control
                  type="text"
                  name="customerName"
                  value={customerName}
                  onChange={customerNameChangeHandler}
                  ref={target}
                />
              </OverlayTrigger>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formCustomerStatus">
            <Form.Label as="legend" column sm={2}>
              Customer Active
            </Form.Label>
            <Col sm={10}>
              <Form.Check
                type="switch"
                name="customerStatus"
                checked={customerStatus}
                onChange={customerStatusChangeHanlder}
              />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={cancelClickHandler}>
          Close
        </Button>
        <Button variant="primary" onClick={addCustomerClickHandler}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewCustomer;
