import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Form,
  OverlayTrigger,
  Col,
  Row,
  Tooltip,
  Button,
} from "react-bootstrap";
import useHttp from "../../hooks/use-http";
import { addKeywords } from "../../lib/api";

export const Keywords = ({ show, handleClose, setRefreshKeywords }) => {
  const [keyword, setKeyword] = useState("");
  const [saveClicked, setSaveClicked] = useState(false);

  const [keywordError, setKeywordError] = useState(false);

  const target = useRef(null);
  const renderKeywordErrorTooltip = (props) => (
    <Tooltip id="keywordError-tooltip" {...props}>
      Enter Analyzer Name!
    </Tooltip>
  );
  const { sendRequest: saveKeywordRequest, data: savedKeywordData, status:saveKeywordStatus} =
    useHttp(addKeywords);

  const saveKeywordClickHandler = (event) => {
    saveKeywordRequest({ keyword: keyword });
    setSaveClicked(true);
    handleClose(true);
  };

  useEffect(()=>{
    console.log(saveKeywordStatus);
    setRefreshKeywords(true);
  },[saveKeywordStatus, setRefreshKeywords])

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add New Keyword</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group as={Row} className="mb-3" controlId="keyword">
            <Form.Label column sm={2}>
              Keyword
            </Form.Label>
            <Col sm={6}>
              <OverlayTrigger
                placement="bottom"
                overlay={renderKeywordErrorTooltip}
                target={target.current}
                show={keywordError}
              >
                <Form.Control
                  type="text"
                  name="schedulerName"
                  value={keyword}
                  onChange={(event) => {
                    setKeyword(event.target.value);
                    setSaveClicked(false);
                  }}
                />
              </OverlayTrigger>
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={saveKeywordClickHandler}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
