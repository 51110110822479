import React from "react";
import { useEffect } from "react";
import useHttp from "../../hooks/use-http";
import { deleteScheduler, getScheduler } from "../../lib/api";
import TableComponent from "../ui/Table";

const tableHeaders = [
  "Sl No",
  "Customer",
  "Site",
  "Router",
  "Scheduler Name",
  "Function",
  "Description",
  "Execution Frequency",
  "Execution Range",
  "Execution Day",
  "Start Time",
  "Action",
];

const ListScheduler = ({ checkData, handleCheckData }) => {
  const { sendRequest: getAllScheduler, data: schedulerData } =
    useHttp(getScheduler);

  useEffect(() => {
    getAllScheduler();
    if (checkData) {
        getAllScheduler();
        handleCheckData(false);    
    }
  }, [checkData, getAllScheduler, handleCheckData]);

  const {sendRequest:deleteSchedulerRequest} = useHttp(deleteScheduler)
  const deleteClickHandler =(id, event) =>{
    event.preventDefault();
    if (window.confirm("Delete Scheduler?")) {
        deleteSchedulerRequest(id)
      }
      handleCheckData(true);
  };
//   console.log(schedulerData);
  return (
    <TableComponent
      tableHeaders={tableHeaders}
      data={schedulerData ? schedulerData : []}
      onDelete={deleteClickHandler}
    />
  );
};

export default ListScheduler;
