import React, {useState} from 'react'
import {Modal, Form, Col, Row, Button} from "react-bootstrap";


const AddSearchKeywords = (props) => {
    const [keyword, setKeyword] = useState("");

  return (
    <Modal
    show={props.show}
    onHide={props.handleClose}
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header closeButton>
        <Modal.Title>Add New Keyword</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form>
            <Form.Group as={Row} className="mb-3" controlId="formKeyword">
              <Form.Label column sm={2}>Keyword</Form.Label>
              <Col sm={6}>
                <Form.Control
                  type="text"
                  name="keyword"
                  value={keyword}
                  onChange={(event) => {
                    setKeyword(event.target.value);
                  }}
                />
              </Col>
            </Form.Group>
            </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={props.handleClose}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
    
  )
}

export default AddSearchKeywords