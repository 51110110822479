import React, {useState} from "react";
import ListRouters from "../components/router/ListRouters";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import AddNewRouter from "../components/router/AddNewRouter";

const Routers = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [checkData, setCheckData] = useState(false);
  return (
    <Card>
      <Card.Header>
        <h4 style={{ display: "inline" }}>Routers</h4>
        <Button
          style={{ float: "right" }}
          variant="primary"
          onClick={handleShow}
        >
          Add New Router
        </Button>
      </Card.Header>
      <Card.Body>
        <ListRouters checkData={checkData} handleCheckData={setCheckData} />
        <AddNewRouter
          show={show}
          handleClose={handleClose}
          handleCheckData={setCheckData}
        />
      </Card.Body>
    </Card>
  );
};

export default Routers;
