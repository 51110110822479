import React, {useState} from "react";
import ListCustomer from "../components/customer/ListCustomer";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import AddNewCustomer from "../components/customer/AddNewCustomer";

const Customer = () => {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [checkData, setCheckData] = useState(false)
  return (
    <Card>
        <Card.Header><h4 style={{ display: "inline" }}>Customers</h4> 
      <Button style={{  float:"right" }} variant="primary" onClick={handleShow}>Add New Customer</Button>
      </Card.Header>
      <Card.Body>
      <ListCustomer  checkData={checkData} handleCheckData={setCheckData}/>
      <AddNewCustomer show={show} handleClose={handleClose} handleCheckData={setCheckData}/>
      </Card.Body>
    </Card>
  );
};

export default Customer;
