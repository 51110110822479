import React from 'react'
import {Card} from "react-bootstrap"
import ShowAnalyzedLogs from '../components/LogAnalyzer/ShowAnalyzedLogs'

const AnalyzedLogs = () => {
  return (
    <Card>
        <Card.Header as="h5">Analyzed Logs</Card.Header>
        <Card.Body>
            <ShowAnalyzedLogs/>
        </Card.Body>
    </Card>
  )
}

export default AnalyzedLogs