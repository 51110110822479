import React, { Fragment, useEffect, useState } from "react";
import CustomerSiteRouter from "../customerSiteRouter/CustomerSiteRouter";
import useHttp from "../../hooks/use-http";
import { getRouterOverview } from "../../lib/api";
import Spinner from "react-bootstrap/Spinner";
import Overview from "./Overview";

const GetRouterOverview = () => {
  const [routerID, setRouterID] = useState("");
  const [showOverview, setShowOverview] = useState(<div></div>);

  const { sendRequest, data, status, error } = useHttp(getRouterOverview);

  useEffect(() => {
    if (routerID) {
      sendRequest(routerID);
    }
  }, [sendRequest, routerID]);

  useEffect(() => {
    if (status === "pending") {
      setShowOverview(
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spinner animation="border" />
        </div>
      );
    } else if (status === "completed") {
      setShowOverview(<Overview overViewData={data} />);
    }
  }, [status, data]);

  return (
    <Fragment>
      <CustomerSiteRouter setRouterID={setRouterID} InContainer={true} />
      {showOverview}
    </Fragment>
  );
};

export default GetRouterOverview;
