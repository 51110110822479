import React from "react";
import { deleteEventExtractionConfiguration, getEventExtractionConfiguration } from "../../lib/api";
import Table from "../ui/Table";
import useHttp from "../../hooks/use-http";
import { useEffect } from "react";

const tableHeaders = ["Sl No", "Customer", "Site", "Router", "Event", "Action"];

const ListEventExtractionConfiguration = ({ checkData, handleCheckData }) => {

  const {
    sendRequest,
    data: eventExtractionConfigurationData,
    status,
    error,
  } = useHttp(getEventExtractionConfiguration);

  useEffect(() => {
    sendRequest();
    if (checkData) {
      sendRequest();
      handleCheckData(false);
    }
  }, [sendRequest, checkData, handleCheckData]);

  const {
    sendRequest: deleteEventExtractionConfigurationRequest,
    data: deleteEventExtractionConfigurationData,
    error: deleteEventExtractionConfigurationError,
    status: deleteEventExtractionConfigurationStatus,
  } = useHttp(deleteEventExtractionConfiguration);

  const deleteClickHandler = (id, event) => {
    if (window.confirm("Delete Site?")) {
      event.preventDefault();
    deleteEventExtractionConfigurationRequest(id)
    }
    handleCheckData(true);
  };

  return (
    <Table
      tableHeaders={tableHeaders}
      data={
        eventExtractionConfigurationData ? eventExtractionConfigurationData : []
      }
      onDelete={deleteClickHandler}
    />
  );
};

export default ListEventExtractionConfiguration;
