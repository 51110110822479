import React, { useEffect } from "react";
import useHttp from "../../hooks/use-http";
import { addRouter, deleteRouter, getAllRouters, setRouterStatus } from "../../lib/api";
import Table from "../ui/Table";

const tableHeaders = [
  "Sl No",
  "Name",
  "Customer",
  "Site",
  "Access ID",
  "Password",
  "Access URL",
  "Brand",
  "Firmware",
  "Status",
  "Connection Attempts",
  "Action",
];

const ListRouters = ({ checkData, handleCheckData }) => {
  const {
    sendRequest,
    data: routerData,
  } = useHttp(getAllRouters);

  useEffect(() => {
    sendRequest();
    if (checkData) {
      sendRequest();
      handleCheckData(false);
    }
  }, [sendRequest, checkData, handleCheckData]);


  const {
    sendRequest: updateRouterStatusRequest,
  } = useHttp(setRouterStatus);

  const statusChangeHandler = (id, event) => {
    if (window.confirm("Toggle Customer Status?")) {
      const customerData = { id: id, status: event.target.checked };
      updateRouterStatusRequest(customerData);
    } else {
      event.target.checked = !event.target.checked;
    }
  };

  const {sendRequest:deleteRouterRequest} = useHttp(deleteRouter)
  const deleteClickHandler = (id, event) => {
    event.preventDefault();
    if (window.confirm("Delete Site?")) {
        deleteRouterRequest(id)
      }
      handleCheckData(true);
  };

  return (
    <Table
      tableHeaders={tableHeaders}
      data={routerData ? routerData : []}
      onStatusChange={statusChangeHandler}
      onDelete={deleteClickHandler}
    />
  );
};

export default ListRouters;
