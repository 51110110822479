import React, { useState, useEffect, Fragment, useRef ,forwardRef, useImperativeHandle, } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import useHttp from "../../hooks/use-http";
import {
    getActiveCustomers,
  getSiteByCustomer,
  getRouterBySite,
} from "../../lib/api";
import { Container,  OverlayTrigger, Tooltip } from "react-bootstrap";

const CustomerSiteRouter = (props) => {
   
  const [customerList, setCustomerList] = useState([
    <option value="select" key="select">
      Select Customer
    </option>,
  ]);

  const [siteList, setSiteList] = useState([
    <option value="select" key="select">
      Select Site
    </option>,
  ]);

  const [routerList, setRouterList] = useState([
    <option value="select" key="select">
      Select Router
    </option>,
  ]);

  const [customerID, setCustomerID] = useState("");
  const [siteID, setSiteID] = useState("");

  const target = useRef(null);




  const renderCustomerIDErrorTooltip = (props) => (
    <Tooltip id="customerid-tooltip" {...props}>
      Select Customer!
    </Tooltip>
  );
  const renderSiteIdErrorTooltip = (props) => (
    <Tooltip id="siteid-tooltip" {...props}>
      Select Site!
    </Tooltip>
  );
  const renderRouterIDErrorTooltip = (props) => (
    <Tooltip id="routerIDError-tooltip" {...props}>
      Enter Router ID!
    </Tooltip>
  );

  const {
    sendRequest: getAllCustomersRequest,
    status,
    data: allCustomersData,
    error,
  } = useHttp(getActiveCustomers);

  useEffect(() => {
    getAllCustomersRequest();
  }, [getAllCustomersRequest]);
  const {
    sendRequest: siteListByCustomerRequest,
    status: siteStatus,
    data: siteByCustomerData,
    error: siteError,
  } = useHttp(getSiteByCustomer);

  useEffect(() => {
    if (customerID) {
      siteListByCustomerRequest(customerID);
    } else {
      return;
    }
  }, [siteListByCustomerRequest, customerID]);

  useEffect(() => {
    if (siteByCustomerData) {
      setSiteList((prevstate) => [
        ...prevstate,
        siteByCustomerData.map((item, index) => {
          return (
            <option value={item.site_id} key={index}>
              {item.site_name}
            </option>
          );
        }),
      ]);
      setRouterList([
        <option value="select" key="select">
          Select Router
        </option>,
      ]);
    } else {
      setSiteList([
        <option value="select" key="select">
          Select Site
        </option>,
      ]);
      setRouterList([
        <option value="select" key="select">
          Select Router
        </option>,
      ]);
    }
  }, [setSiteList, siteByCustomerData]);

  const {
    sendRequest: routerListBySiteRequest,
    status: routerStatus,
    data: routerBySiteData,
    error: routerError,
  } = useHttp(getRouterBySite);

  useEffect(() => {
    if (routerBySiteData) {
      setRouterList((prevstate) => [
        ...prevstate,
        routerBySiteData.map((item, index) => {
          return (
            <option value={item.router_id} key={index}>
              {item.router_name}
            </option>
          );
        }),
      ]);
    } else {
      setRouterList([
        <option value="select" key="select">
          Select Router
        </option>,
      ]);
    }
  }, [setRouterList, routerBySiteData]);

  useEffect(() => {
    if (siteID) {
      routerListBySiteRequest(siteID);
    } else {
      return;
    }
  }, [routerListBySiteRequest, siteID]);

  useEffect(()=>{
    if(allCustomersData){
    setCustomerList((prevState) => (
        
        [
        
        [
            <option value="select" key="select">
              Select Customer
            </option>,
          ],
        allCustomersData.map((item, index) => {
          return (
            <option value={item.id} key={index}>
              {item.customer_name}
            </option>
          );
        }),
      ]));
    }else{
        setCustomerList([
            <option value="select" key="select">
              Select Customer
            </option>,
          ])
    }
  },[allCustomersData,setCustomerList])

  

  const CustomerSiteRouterForm = (
    <Form action="" method="post">
      <Row>
        <Col>
          <Form.Group as={Row} className="mb-3" controlId="selectCustomer">
            <Form.Label>Select Customer</Form.Label>
            <Col sm={12}>
            <OverlayTrigger
                  placement="bottom"
                  overlay={renderCustomerIDErrorTooltip}
                  target={target.current}
                  show={props.customerIDError ? props.customerIDError : false}
                >
              <Form.Select
                name="selectCustomer"
                onChange={(event) => {
                    setCustomerID(event.target.value);
                  if (props.setCustomerID) {
                    props.setCustomerID(event.target.value);
                  }
                }}
              >
                {customerList}
              </Form.Select>
              </OverlayTrigger>
            </Col>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row} className="mb-3" controlId="formSite">
            <Form.Label>Select Site</Form.Label>
            <Col sm={12}>
            <OverlayTrigger
                  placement="bottom"
                  overlay={renderSiteIdErrorTooltip}
                  target={target.current}
                  show={props.siteIDError ? props.siteIDError: false}
                >
              <Form.Select
                name="selectSite"
                onChange={(event) => {
                    setSiteID(event.target.value);
                  if (props.setSiteID) {
                    props.setSiteID(event.target.value);
                  }
                }}
              >
                {siteList}
              </Form.Select>
              </OverlayTrigger>
            </Col>
          </Form.Group>
        </Col>
        {props.hideRouter ? null : (
          <Col>
            <Form.Group as={Row} className="mb-3" controlId="formSelectRouter">
              <Form.Label>Select Router</Form.Label>
              <Col sm={12}>
              <OverlayTrigger
                  placement="bottom"
                  overlay={renderRouterIDErrorTooltip}
                  target={target.current}
                  show={props.routerIDError ? props.routerIDError : false}
                >
                <Form.Select
                  name="selectRouter"
                  onChange={(event) => {
                    if (props.setRouterID) {
                      props.setRouterID(event.target.value);
                    }
                  }}
                >
                  {routerList}
                </Form.Select>
                </OverlayTrigger>
              </Col>
            </Form.Group>
          </Col>
        )}
      </Row>
    </Form>
  );
  if (props.InContainer) {
    return (
      <Fragment>
        <Container>{CustomerSiteRouterForm}</Container>
      </Fragment>
    );
  } else {
    return <Fragment>{CustomerSiteRouterForm}</Fragment>;
  }
};

export default CustomerSiteRouter;
