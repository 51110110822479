import React from 'react'
import {Card} from 'react-bootstrap'
import ListExternalHttpAccessLog from '../components/externalHttpAccess/listExternalHttpAccessLog'


const ExternalHttpAccessLog = () => {
  return (
    <Card>
        <Card.Header as="h5">External Http Access Logs</Card.Header>
        <Card.Body>
            <ListExternalHttpAccessLog/>
    </Card.Body>
    </Card>
  )
}

export default ExternalHttpAccessLog