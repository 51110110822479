import React, {useState} from 'react'
import { Button, Card } from 'react-bootstrap';
import AddLogAnalyzer from '../components/LogAnalyzer/AddLogAnalyzer';
import { Keywords } from '../components/LogAnalyzer/Keywords';
import ListLogAnalyzer from '../components/LogAnalyzer/ListLogAnalyzer';

const LogAnalyzer = () => {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
   
  const [showKeyword, setShowKeyword] = useState(false);

  const handleKeywordClose = () => setShowKeyword(false);
  const handleKeywordShow = () => setShowKeyword(true);
  const [checkData, setCheckData] = useState(false);

  const [refreshKeywords, setRefreshKeywords] = useState(false);

    console.log(showKeyword);
  return (
    <Card>
        <Card.Header>
        <h4 style={{ display: "inline" }}>Log Analyzer</h4>
        
        <Button style={{ float: "right" }} variant="primary" onClick={handleShow}>
        Add New Analyzer
      </Button>{'  '}
      <Button style={{ float: "right" , marginRight:"10px" }} variant="primary" onClick={handleKeywordShow}>
        Add Keywords
      </Button>
        </Card.Header>
        <Card.Body>
            <ListLogAnalyzer checkData={checkData} handleCheckData={setCheckData}/>
        <AddLogAnalyzer show={show} handleClose={handleClose} handleCheckData={setCheckData} refreshKeywords={refreshKeywords}/>
        <Keywords show={showKeyword} handleClose={handleKeywordClose} setRefreshKeywords={setRefreshKeywords}/>
      </Card.Body>
    </Card>
  )
}

export default LogAnalyzer