import React, { Fragment, useState } from "react";
import CustomerSiteRouter from "../customerSiteRouter/CustomerSiteRouter";
import { Button, Toast, ToastContainer } from "react-bootstrap";
import { useEffect } from "react";
import useHttp from "../../hooks/use-http";
import { downloadEventLogs, extractEvents } from "../../lib/api";

const ExtractEventsManual = () => {
  const [routerID, setRouterID] = useState("");
  const [showButtons, setShowButtons] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastHeader, setToastHeader] = useState("");

  const {
    sendRequest: downloadEventLogRequest,
    data: downloadEventLogData,
    status: downloadEventLogStatus,
  } = useHttp(downloadEventLogs);

  const {
    sendRequest: extractEventsRequest,
    data: extractEventsData,
    status: extractEventsStatus,
  } = useHttp(extractEvents);



  useEffect(() => {
    if (routerID) {
      setShowButtons(true);
    }
  }, [routerID]);

  const downloadLogClickHandler = (event) => {
    event.preventDefault();
    downloadEventLogRequest(routerID);
  };

  useEffect(() => {
    if (downloadEventLogStatus === "completed") {
      setShowToast(true);
      setToastHeader("Download Logs")
      setToastMessage(downloadEventLogData);
    }
  }, [downloadEventLogData, downloadEventLogStatus]);


  const extractEventsClickHandler = (event) => {
    event.preventDefault();
    extractEventsRequest(routerID)
  };

  console.log(extractEventsData, extractEventsStatus)

  useEffect(() => {
    if (extractEventsStatus === "completed") {
      setShowToast(true);
      setToastHeader("Event Extraction")
      setToastMessage(extractEventsData);
    }
  }, [extractEventsData, extractEventsStatus]);

  return (
    <Fragment>
      <CustomerSiteRouter setRouterID={setRouterID} />
      <ToastContainer className="p-3" position="top-center">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={2000}
          autohide
        >
          <Toast.Header closeButton={false}>
            <strong className="me-auto">{toastHeader}</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
      {showButtons ? (
        <Fragment>
          <Button variant="primary" onClick={downloadLogClickHandler}>
            Download Logs
          </Button>{" "}
          <Button variant="primary" onClick={extractEventsClickHandler}>
            Extract Events
          </Button>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default ExtractEventsManual;
