import React, { useEffect } from "react";
import useHttp from "../../hooks/use-http";
import { getSchedulerLog } from "../../lib/api";
import TableComponent from "../ui/Table";

const tableHeaders = [
  "Sl No",
  "Scheduler Name",
  "Scheduler Function",
  "Run Date-Time",
  "status",
];

const ShowSchedulerRunLogs = () => {
  const { sendRequest, data, status, error } = useHttp(getSchedulerLog);
  useEffect(() => {
    sendRequest();
  }, [sendRequest]);
  return (
    <TableComponent
      tableHeaders={tableHeaders}
      data={data ? data : []}
      listOnly={true}
    />
  );
};

export default ShowSchedulerRunLogs;
