import React, { useEffect } from "react";
import useHttp from "../../hooks/use-http";
import { getPasswordResetLog } from "../../lib/api";
import TableComponent from "../ui/Table";

const tableHeaders = [
  "Sl No",
  "User ID",
  "Router Name",
  "Current Date-time",
  "Password Reset Status",
];

const ListPassowrdResetLog = () => {
  const { sendRequest, data, status, error } = useHttp(getPasswordResetLog);

  useEffect(() => {
    sendRequest();
  }, [sendRequest]);

  return (
    <TableComponent
      tableHeaders={tableHeaders}
      data={data ? data : []}
      listOnly={true}
    />
  );
};

export default ListPassowrdResetLog;
