import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useState, useEffect } from "react";
import useHttp from "../../hooks/use-http";
import { getRouterPortInfoList, addRouterPorts } from "../../lib/api";

const AddRouterPorts = ({routerID, setCheckData}) => {
  const [portName, setPortName] = useState("");
  const [portDescription, setPortDescription] = useState("");

  const { sendRequest: getPortInfoListRequest, data: portInfoListData } =
    useHttp(getRouterPortInfoList);

  const [portsList, setPortsList] = useState([
    <option value="select" key="select">
      Select Port
    </option>,
  ]);

  useEffect(() => {
    if (portInfoListData) {
      setPortsList(() => [
        <option value="select" key="select">
          Select Port
        </option>,
        portInfoListData.map((item, index) => [
          <option
            value={item.port_name + " : " + item.port_description}
            key={index}
          >
            {item.port_name + " : " + item.port_description}
          </option>,
        ]),
      ]);
    }
  }, [portInfoListData]);

  const { sendRequest: addRouterPortsRequest, data: addRouterPortsData, status : addRouterPortsStatus } =
    useHttp(addRouterPorts);

  const addPortClickHandler = (event) => {
    event.preventDefault();
    console.log(portName + " " + portDescription);
    const routerPortsdata = {
      router_id: routerID,
      ports_array: [portName + ":" + portDescription],
    };
    addRouterPortsRequest(routerPortsdata);
  };
  
  useEffect(()=>{
    if(addRouterPortsStatus==="completed"){
        setCheckData(true);
    }
  },[addRouterPortsStatus, setCheckData])
  
  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header as="h5">Add New Port</Accordion.Header>
        <Accordion.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group as={Row} className="mb-3" controlId="selectPort">
                  <Form.Label>Select Port</Form.Label>
                  <Col sm={12}>
                    <Form.Select
                      name="selectPort"
                      onClick={getPortInfoListRequest}
                      onChange={(event) => {
                        if (event.target.value.includes(" : ")) {
                          console.log(event.target.value);
                          const selectedText = event.target.value;
                          setPortDescription(selectedText.split(":")[1]);
                          setPortName(selectedText.split(":")[0]);
                          setPortsList(
                            <option value={selectedText} key="select">
                              {selectedText.split(":")[0]}
                            </option>
                          );
                        }
                      }}
                    >
                      {portsList}
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
              <Col md="8">
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="portDescription"
                >
                  <Form.Label>Port Description</Form.Label>
                  <Col sm={12}>
                    <Form.Control
                      type="input"
                      name="portDescription"
                      value={portDescription}
                      onChange={(event) => {
                        setPortDescription(event.target.value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col>
                <Form.Label>Action</Form.Label>
                <Col sm={12}>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={addPortClickHandler}
                  >
                    Add
                  </Button>
                </Col>
              </Col>
            </Row>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AddRouterPorts;
