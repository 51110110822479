import React, { useState, useRef } from "react";
import CustomerSiteRouter from "../customerSiteRouter/CustomerSiteRouter";
import {
  Form,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
  Button,
  Modal,
} from "react-bootstrap";
import { useEffect } from "react";
import useHttp from "../../hooks/use-http";
import { addScheduler } from "../../lib/api";

const AddScheduler = ({ show, handleCheckData, handleClose }) => {
  const [customerID, setCustomerID] = useState("");
  const [siteID, setSiteID] = useState("");
  const [routerID, setRouterID] = useState("");
  const [schedulerName, setSchedulerName] = useState("");
  const [functionName, setFunctionName] = useState("");
  const [functionNameList, setFunctionNameList] = useState([
    <option value="select" key="select">
      Select Function
    </option>,
    <option value="download_log" key="download_log">
      download_log
    </option>,
    <option value="reset_password" key="reset_password">
      reset_password
    </option>,
  ]);
  const [description, setDescription] = useState("");
  const [executeEvery, setExecuteEvery] = useState("");
  const [executeAt, setExecuteAt] = useState("");
  const [saveClicked, setSaveClicked] = useState(false);

  const [customerIDError, setCustomerIDError] = useState(false);
  const [siteIDError, setSiteIDError] = useState(false);
  const [routerIDError, setRouterIDError] = useState(false);
  const [schedulerNameError, setSchedulerNameError] = useState(false);
  const [functionNameError, setFunctionNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [executeEveryError, setExecuteEveryError] = useState(false);
  const [executeAtError, setExecuteAtError] = useState(false);

  const target = useRef(null);

  const renderSchedulerNameErrorTooltip = (props) => (
    <Tooltip id="schedulerNameError-tooltip" {...props}>
      Enter Scheduler Name!
    </Tooltip>
  );
  const renderFunctionNameErrorTooltip = (props) => (
    <Tooltip id="functionNameError-tooltip" {...props}>
      Select Scheduler Function!
    </Tooltip>
  );
  const renderDescriptionErrorTooltip = (props) => (
    <Tooltip id="descriptionError-tooltip" {...props}>
      Enter Description For Scheduler!
    </Tooltip>
  );
  const renderExecuteEveryErrorTooltip = (props) => (
    <Tooltip id="executeEveryError-tooltip" {...props}>
      Select Exection Frequency!
    </Tooltip>
  );
  const renderExecuteAtErrorTooltip = (props) => (
    <Tooltip id="executeAtError-tooltip" {...props}>
      Select Exection Time!
    </Tooltip>
  );

  useEffect(() => {
    if (customerID) {
      setCustomerIDError(false);
      setSaveClicked(false);
    }
    if (siteID) {
      setSiteIDError(false);
      setSaveClicked(false);
    }
    if (routerID) {
      setRouterIDError(false);
      setSaveClicked(false);
    }
  }, [customerID, routerID, siteID]);

  const { sendRequest: addSchedulerRequest, error: addSchdulerError } =
    useHttp(addScheduler);

  useEffect(() => {
    if (
      !customerIDError &&
      !siteIDError &&
      !routerIDError &&
      !schedulerNameError &&
      !functionNameError &&
      !descriptionError &&
      !executeEveryError &&
      !executeAtError &&
      saveClicked
    ) {
      const schedulerData = {
        customer: [customerID],
        site: [siteID],
        router: [routerID],
        scheduler_name: schedulerName,
        select_function: functionName,
        description: description,
        execute_count: executeEvery,
        start_time: executeAt,
      };
      addSchedulerRequest(schedulerData);
      setSaveClicked(false);

      if (!addSchdulerError) {
        handleClose(true);
        handleCheckData(true);
        setSchedulerName("");
        setFunctionName("");
        setDescription("");
        setExecuteEvery("");
        setExecuteAt("");
      }
    }
  }, [
    addSchdulerError,
    addSchedulerRequest,
    customerID,
    customerIDError,
    description,
    descriptionError,
    executeAt,
    executeAtError,
    executeEvery,
    executeEveryError,
    functionName,
    functionNameError,
    handleCheckData,
    handleClose,
    routerID,
    routerIDError,
    saveClicked,
    schedulerName,
    schedulerNameError,
    siteID,
    siteIDError,
  ]);

  const saveSchedulerClickHandler = () => {
    if (customerID === "select" || customerID.trim() === "") {
      setCustomerIDError(true);
      setSaveClicked(false);
    }
    if (siteID === "select" || siteID.trim() === "") {
      setSiteIDError(true);
      setSaveClicked(false);
    }
    if (routerID === "select" || routerID.trim() === "") {
      setRouterIDError(true);
      setSaveClicked(false);
    }
    if (
      !schedulerName ||
      schedulerName.trim() === "" ||
      schedulerName.trim().length === 0
    ) {
      setSchedulerNameError(true);
      setSaveClicked(false);
    }
    if (
      !functionName ||
      functionName.trim() === "" ||
      functionName.trim().length === 0
    ) {
      setFunctionNameError(true);
      setSaveClicked(false);
    }
    if (
      !description ||
      description.trim() === "" ||
      description.trim().length === 0
    ) {
      setDescriptionError(true);
      setSaveClicked(false);
    }
    if (
      !executeEvery ||
      executeEvery.trim() === "" ||
      executeEvery.trim().length === 0
    ) {
      setExecuteEveryError(true);
      setSaveClicked(false);
    }
    if (
      !executeAt ||
      executeAt.trim() === "" ||
      executeAt.trim().length === 0
    ) {
      setExecuteAtError(true);
      setSaveClicked(false);
    }
    setSaveClicked(true);
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add New Scheduler</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomerSiteRouter
          setCustomerID={setCustomerID}
          customerIDError={customerIDError}
          setSiteID={setSiteID}
          siteIDError={siteIDError}
          setRouterID={setRouterID}
          routerIDError={routerIDError}
        />
        <Form>
          <Form.Group as={Row} className="mb-3" controlId="formSchedulerName">
            <Form.Label column sm={2}>
              Scheduler Name
            </Form.Label>
            <Col sm={6}>
              <OverlayTrigger
                placement="bottom"
                overlay={renderSchedulerNameErrorTooltip}
                target={target.current}
                show={schedulerNameError}
              >
                <Form.Control
                  type="text"
                  name="schedulerName"
                  value={schedulerName}
                  onChange={(event) => {
                    setSchedulerName(event.target.value);
                    setSchedulerNameError(false);
                    setSaveClicked(false);
                  }}
                />
              </OverlayTrigger>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formFunctionName">
            <Form.Label column sm={2}>
              Function Name
            </Form.Label>
            <Col sm={6}>
              <OverlayTrigger
                placement="bottom"
                overlay={renderFunctionNameErrorTooltip}
                target={target.current}
                show={functionNameError}
              >
                <Form.Select
                  name="functionName"
                  onChange={(event) => {
                    setFunctionName(event.target.value);
                    setFunctionNameError(false);
                    setSaveClicked(false);
                  }}
                >
                  {functionNameList}
                </Form.Select>
              </OverlayTrigger>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formDescription">
            <Form.Label column sm={2}>
              Description
            </Form.Label>
            <Col sm={6}>
              <OverlayTrigger
                placement="bottom"
                overlay={renderDescriptionErrorTooltip}
                target={target.current}
                show={descriptionError}
              >
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  value={description}
                  onChange={(event) => {
                    setDescription(event.target.value);
                    setDescriptionError(false);
                    setSaveClicked(false);
                  }}
                />
              </OverlayTrigger>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formExecuteEvery">
            <Form.Label column sm={2}>
              Execute Every
            </Form.Label>
            <Col sm={6}>
              <OverlayTrigger
                placement="bottom"
                overlay={renderExecuteEveryErrorTooltip}
                target={target.current}
                show={executeEveryError}
              >
                <Form.Control
                  type="text"
                  rows={3}
                  name="executeEvery"
                  value={executeEvery}
                  onChange={(event) => {
                    setExecuteEvery(event.target.value);
                    setExecuteEveryError(false);
                    setSaveClicked(false);
                  }}
                />
              </OverlayTrigger>
            </Col>
            <Form.Label column sm={2}>
              Days
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formExecuteAt">
            <Form.Label column sm={2}>
              Execute at
            </Form.Label>
            <Col sm={6}>
              <OverlayTrigger
                placement="bottom"
                overlay={renderExecuteAtErrorTooltip}
                target={target.current}
                show={executeAtError}
              >
                <Form.Control
                  type="time"
                  rows={3}
                  name="executeAt"
                  value={executeAt}
                  onChange={(event) => {
                    setExecuteAt(event.target.value);
                    setExecuteAtError(false);
                    setSaveClicked(false);
                  }}
                />
              </OverlayTrigger>
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={saveSchedulerClickHandler}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddScheduler;
