import React from 'react'
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from 'react-bootstrap/Col'

const Overview = (props) => {
    console.log(props.overViewData);
    if (props.overViewData){
  return (
    <Card>
        <Card.Header as="h5">{props.overViewData.router_name} Overview</Card.Header>
        <Card.Body>
            <Row>
                <Col>
                Customer Name
                </Col>
                <Col>
                :{props.overViewData.customer_name}
                </Col>
            </Row>
            <Row>
                <Col>
                Site Name
                </Col>
                <Col>
                :{props.overViewData.site_name}
                </Col>
            </Row>
            <Row>
                <Col>
                Router Name
                </Col>
                <Col>
                :{props.overViewData.router_name}
                </Col>
            </Row>
            <Row>
                <Col>
                Firmware
                </Col>
                <Col>
                :{props.overViewData.firmware}
                </Col>
            </Row>
            <Row>
                <Col>
                Router Uptime
                </Col>
                <Col>
                :{props.overViewData.router_uptime}
                </Col>
            </Row>
            <Row>
                <Col>
                Local Device Time
                </Col>
                <Col>
                :{props.overViewData.local_device_time}
                </Col>
            </Row>
            <Row>
                <Col>
                RAM Memory Usage
                </Col>
                <Col>
                :{props.overViewData.memoryusage_RAM}
                </Col>
            </Row>
            <Row>
                <Col>
                Flash Memory Usage
                </Col>
                <Col>
                :{props.overViewData.memoryusage_Flash}
                </Col>
            </Row>
            <Row>
                <Col>
                Current Date-time
                </Col>
                <Col>
                :{props.overViewData.curr_datetime}
                </Col>
            </Row>
            <Row>
                <Col>
                Local IP Address
                </Col>
                <Col>
                :{props.overViewData.ip_add}
                </Col>
            </Row>
        </Card.Body>
    </Card>
  )
}else{
    return(
        <Card>
        <Card.Header as="h5">No Data Found</Card.Header>
        </Card>
    )
}
}

export default Overview