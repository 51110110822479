import React, { Fragment, useState, useEffect } from "react";
import CustomerSiteRouter from "../customerSiteRouter/CustomerSiteRouter";

import { Form, Col, Row, Button } from "react-bootstrap";
import useHttp from "../../hooks/use-http";
import { addShowPasswordLog, getRouterCredentials } from "../../lib/api";

const ShowPassword = () => {
  const [routerID, setRouterID] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [accessId, setAccessId] = useState("");
  const [password, setPassword] = useState("");
  const [fieldType, setFieldType] = useState("password");
  const [enabled, setEnabled] = useState(true);
  const [revealCredentialsComponent, setRevealCredentialsComponent] = useState(
    <div></div>
  );

  const {
    sendRequest: getRouterCredentialsRequest,
    data: routerCredentialsData,
  } = useHttp(getRouterCredentials);

  useEffect(() => {
    if (routerID) {
      getRouterCredentialsRequest(routerID);
    }
  }, [getRouterCredentialsRequest, routerID]);

  useEffect(() => {
    if (routerCredentialsData) {
      setShowInfo(true);
      setAccessId(routerCredentialsData.ra);
      setPassword(routerCredentialsData.rp);
    }
  }, [routerCredentialsData]);

  const { sendRequest: addShowPasswordLogRequest } =
    useHttp(addShowPasswordLog);

  useEffect(() => {
    if (routerID && fieldType === "text") {
      addShowPasswordLogRequest(routerID);
    }
  }, [addShowPasswordLogRequest, fieldType, routerID]);

  const showPasswordClickHandler = (event) => {
    event.preventDefault();
    setFieldType("text");
    setEnabled(false);
  };

  const sendEmailClickHandler = (event) => {
    event.preventDefault();
  };
  const cancelClickHandler = (event) => {
    setFieldType("password");
    setEnabled(false);
    setAccessId("");
    setPassword("");
    setShowInfo("");
  };
  useEffect(() => {
    if (showInfo) {
      setRevealCredentialsComponent(
        <Fragment>
          <Form>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formRouterAccessId"
            >
              <Form.Label>Access ID</Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="accessId"
                  value={accessId}
                  onChange={(event) => {
                    setAccessId(event.target.value);
                  }}
                  disabled={true}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formRouterPassword"
            >
              <Form.Label>Password</Form.Label>
              <Col sm={10}>
                <Form.Control
                  type={fieldType}
                  name="password"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  disabled={true}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Col sm={{ span: 10, offset: 2 }}>
                <Button
                  type="submit"
                  variant="primary"
                  onClick={showPasswordClickHandler}
                >
                  Show Password
                </Button>{" "}
                <Button
                  type="submit"
                  variant="primary"
                  onClick={sendEmailClickHandler}
                  disabled={enabled}
                >
                  Send Email
                </Button>{" "}
                <Button type="cancel" onClick={cancelClickHandler}>
                  Cancel
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Fragment>
      );
    }
  }, [showInfo, accessId, password, fieldType, enabled]);

  return (
    <Fragment>
      <CustomerSiteRouter setRouterID={setRouterID} />
      {revealCredentialsComponent}
    </Fragment>
  );
};

export default ShowPassword;
