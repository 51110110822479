import React, { useEffect } from "react";
import useHttp from "../../hooks/use-http";
import { getExternalHttpAccessLog } from "../../lib/api";
import TableComponent from "../ui/Table";

const tableHeaders = [
  "Sl No",
  "User ID",
  "Router Name",
  "Local IP Address",
  "Current Date-time",
  "Enabled",
];

const ListExternalHttpAccessLog = () => {
  const { sendRequest, data, status, error } = useHttp(
    getExternalHttpAccessLog
  );

  useEffect(() => {
    sendRequest();
  }, [sendRequest]);
  return (
    <TableComponent
      tableHeaders={tableHeaders}
      data={data ? data : []}
      listOnly={true}
    />
  );
};

export default ListExternalHttpAccessLog;
