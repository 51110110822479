import React from "react";
import style from "./Table.module.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

const TableComponent = (props) => {
  return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            {props.tableHeaders.map((header, index) => {
              return (
                <th className={style["th"]} key={index}>
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {props.data.map((item, index) => {
            let parsed_data = Object.values(item);
            let cleaned_parsed_data = parsed_data.slice(1, parsed_data.length);
            let count = index + 1;
            // console.log(parsed_data);
            
            if(props.listOnly){
                cleaned_parsed_data = [count, ...cleaned_parsed_data];
            }else{
            cleaned_parsed_data = [count, ...cleaned_parsed_data, "b"];
        }
            return (
              <tr key={index}>
                {cleaned_parsed_data.map((value, idx) => {
                  if (typeof value == "boolean") {
                    // console.log("PARSED DATA" + parsed_data);
                    return (
                        <td key={idx} style={{ textAlign: "center"}} >
                        <Form.Check
                          type="switch"
                          id={parsed_data[0]}
                          value={parsed_data[0]}
                          defaultChecked={value ? true : false}
                          onChange={props.onStatusChange.bind(this, parsed_data[0])}
                        />
                      </td>
                    );
                  } else if (value === "b" && props.editButtonRequired) {
                    return (
                      <td key={idx}>
                        <Button id={parsed_data[0]}>edit</Button>{' '}
                        <Button id={parsed_data[0]} onClick={props.deleteClickHandler}>Delete</Button>
                      </td>
                    );
                  } else if (value === "b" && !props.editButtonRequired) {
                    return (
                      <td key={idx}>
                        <Button id={parsed_data[0]} onClick={props.onDelete.bind(this, parsed_data[0])}>Delete</Button>
                      </td>
                    );
                  }else if (props.onDataClick !==undefined) {
                    return <td key={idx} onClick={props.onDataClick.bind(this, parsed_data[0])}>{value}</td>;
                  }
                  else {
                    // console.log(" VALUE " + value);
                    return <td key={idx}>{value}</td>;
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
  );
};

export default TableComponent;
