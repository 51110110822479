import Layout from "./components/Layout/Layout";
import { Redirect, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Customer from "./pages/Customer";
import Sites from "./pages/Sites";
import Routers from "./pages/Routers";
import AddCustomer from "./components/customer/AddCustomer";
import AddSite from "./components/site/AddSite";
import AddRouter from "./components/router/AddRouter"; import EventExtractionConfiguration from "./pages/EventExtractionConfiguration";
import AddEventConfiguration from "./components/eventExtraction/AddEventConfiguration";
import RouterPorts from "./pages/RouterPorts";
import RouterOverview from "./pages/RouterOverview";
import RouterOverviewLog from "./pages/RouterOverviewLog";
import Scheduler from "./pages/Scheduler";
import RevealPassword from "./pages/RevealPassword";
import RevealPasswordLog from "./pages/RevealPasswordLog";
import RenameRouter from "./pages/RenameRouter"
import ExternalHttpAccess from "./pages/ExternalHttpAccess";
import EventExtractionManualTrigger from "./pages/EventExtractionManualTrigger";
import EventExtractionLog from "./pages/EventExtractionLog";
import ExternalHttpAccessLogs from "./pages/ExternalHttpAccessLogs";
import PasswordResetLog from "./pages/PasswordResetLog";
import SchedulerLog from "./pages/SchedulerLog";
import CronLog from "./pages/CronLog";
import ConnectionLog from "./pages/ConnectionLog";
import AddScheduler from "./components/scheduler/AddScheduler";
import LogAnalyzer from "./pages/LogAnalyzer";
import AnalyzedLogs from "./pages/AnalyzedLogs";
import { useState, useContext } from "react";
import Login from "./pages/Login";
import AuthContext from "./store/auth-context";
import Logout from "./pages/Logout";
import SearchKeywords from "./pages/SearchKeywords";

function App() {
    const authCtx = useContext(AuthContext);
    if(authCtx.isLoggedIn){
  return (
    <Layout>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/home" />
        </Route>
        <Route path="/home" exact>
          <Home />
        </Route>
        <Route path="/customer" exact>
          <Customer />
        </Route>
        <Route path="/customer/add_customer" exact>
          <AddCustomer />
        </Route>
        
        <Route path="/site" exact>
          <Sites />
        </Route>
        <Route path="/site/add_site" exact>
          <AddSite />
        </Route>


        <Route path="/router" exact>
          <Routers />
        </Route>
        <Route path="/router/add_router" exact>
          <AddRouter />
        </Route>

        <Route path="/router/overview" exact>
          <RouterOverview />
        </Route>
        <Route path="/router/overview_log" exact>
          <RouterOverviewLog />
        </Route>

        <Route path="/router/reveal_password" exact>
          <RevealPassword />
        </Route>
        <Route path="/router/reveal_password_log" exact>
          <RevealPasswordLog />
        </Route>

        <Route path="/router/rename" exact>
          <RenameRouter />
        </Route>
        
        <Route path="/router/external_http_access" exact>
          <ExternalHttpAccess />
        </Route>
        <Route path="/router/external_http_access/log" exact>
          <ExternalHttpAccessLogs />
        </Route>


        <Route path="/router/event_extraction" exact>
          <EventExtractionConfiguration />
        </Route>
        <Route path="/router/event_extraction/add_event_extraction" exact>
          <AddEventConfiguration />
        </Route>
        <Route path="/router/event_extraction/manual_trigger" exact>
          <EventExtractionManualTrigger />
        </Route>
        <Route path="/router/event_extraction/log" exact>
          <EventExtractionLog />
        </Route>

        <Route path="/router/password_reset/log" exact>
          <PasswordResetLog />
        </Route>

        <Route path="/router/connection/log" exact>
          <ConnectionLog />
        </Route>


        <Route path="/manage_ports" exact>
          <RouterPorts />
        </Route>


        <Route path="/scheduler" exact>
          <Scheduler />
        </Route>
        <Route path="/scheduler/add_scheduler" exact>
          <AddScheduler />
        </Route>
        <Route path="/scheduler/log" exact>
          <SchedulerLog />
        </Route>
        <Route path="/cron/log" exact>
          <CronLog />
        </Route>

        <Route path="/log_analyzer" exact>
          <LogAnalyzer />
        </Route>
        <Route path="/log_analyzer/analyzed_logs" exact>
          <AnalyzedLogs />
        </Route>
        <Route path="/log_analyzer/search_keywords" exact>
          <SearchKeywords />
        </Route>
        
      <Route path="/logout" exact>
        <Logout/>
      </Route>
      </Switch>
     

    </Layout>
   
  ) }else{
    return(<Login/>)
};
}

export default App;
