import React, {useState} from "react";
import { Card, Button } from "react-bootstrap";
import AddSearchKeywords from "../components/searchKeywords/AddSearchKeywords";
import ListSearchKeywords from "../components/searchKeywords/ListSearchKeywords";

const SearchKeywords = () => {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Card>
      <Card.Header>
        <h4 style={{ display: "inline" }}>Search Keywords</h4>
        <Button
          style={{ float: "right" }}
          variant="primary"
          onClick={handleShow}
        >
          Add New Keyword
        </Button>
      </Card.Header>
      <Card.Body>
        <ListSearchKeywords />
        <AddSearchKeywords show={show} handleClose={handleClose} />
      </Card.Body>
    </Card>
  );
};

export default SearchKeywords;
