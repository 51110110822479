import React, { useState, useRef, useEffect } from "react";
import CustomerSiteRouter from "../customerSiteRouter/CustomerSiteRouter";
import {
  Row,
  Col,
  Tooltip,
  Form,
  Button,
  Modal,
  OverlayTrigger,
} from "react-bootstrap";
import { addLogAnalyzer, getkeywords } from "../../lib/api";
import useHttp from "../../hooks/use-http";

const AddLogAnalyzer = ({ show, handleCheckData, handleClose, refreshKeywords }) => {
  const [customerID, setCustomerID] = useState("");
  const [siteID, setSiteID] = useState("");
  const [routerID, setRouterID] = useState("");
  const [analyzerName, setAnalyzerName] = useState("");
  const [logAnalyzerType, setLogAnalyzerType] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [teamsChannel, setTeamsChannel] = useState("");
  const [logAnalyzerStatus, setlogAnalyzerStatus] = useState(true);

  const [saveClicked, setSaveClicked] = useState(false);

  const [customerIDError, setCustomerIDError] = useState(false);
  const [siteIDError, setSiteIDError] = useState(false);
  const [routerIDError, setRouterIDError] = useState(false);
  const [analyzerNameError, setAnalyzerNameError] = useState(false);
  const [logAnalyzerTypeError, setLogAnalyzerTypeError] = useState(false);
  const [searchTermError, setSearchTermError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [teamsChannelError, setTeamsChannelError] = useState(false);

  const target = useRef(null);

  const renderAnalyzerNameErrorTooltip = (props) => (
    <Tooltip id="analyzerNameError-tooltip" {...props}>
      Enter Analyzer Name!
    </Tooltip>
  );
  const renderLogAnalyzerTypeErrorTooltip = (props) => (
    <Tooltip id="logAnalyzerTypeError-tooltip" {...props}>
      Select Analyzer Type!
    </Tooltip>
  );
  const renderSearchTermErrorTooltip = (props) => (
    <Tooltip id="searchTermError-tooltip" {...props}>
      Select Search Term!
    </Tooltip>
  );
  const renderDescriptionErrorTooltip = (props) => (
    <Tooltip id="descriptionError-tooltip" {...props}>
      Enter Description!
    </Tooltip>
  );
  const renderEmailErrorTooltip = (props) => (
    <Tooltip id="emailError-tooltip" {...props}>
      Enter valid email address!
    </Tooltip>
  );
  const renderTeamsChannelErrorTooltip = (props) => (
    <Tooltip id="teamsChannelError-tooltip" {...props}>
      Enter valid teams channel!
    </Tooltip>
  );

  const [logAnalyzerTypeList, setLogAnalyzerTypeList] = useState([
    <option value="select" key="select">
      Select Type
    </option>,
    <option value="1" key="manual">
      Manual
    </option>,
    <option value="2" key="automatic">
      Automatic
    </option>,
  ]);
  const { sendRequest: getKeywordsRequest, data: keywordsData } =
    useHttp(getkeywords);
  const [searchTermList, setSearchTermList] = useState([
    <option value="select" key="select">
      Select Search Term
    </option>,
  ]);
  useEffect(() => {
    console.log(refreshKeywords);
    if (keywordsData) {
      console.log(keywordsData);
      setSearchTermList([
        keywordsData.map((item, index) => {
          return (
            <option value={item.keyword} key={index}>
              {item.keyword}
            </option>
          );
        }),
      ]);
    } else if(refreshKeywords) {
      getKeywordsRequest();
    } else {
      getKeywordsRequest();
    }
  }, [getKeywordsRequest, keywordsData, refreshKeywords]);

  useEffect(() => {
    if (customerID) {
      setCustomerIDError(false);
      setSaveClicked(false);
    }
    if (siteID) {
      setSiteIDError(false);
      setSaveClicked(false);
    }
    if (routerID) {
      setRouterIDError(false);
      setSaveClicked(false);
    }
  }, [customerID, routerID, siteID]);

  const { sendRequest: addLogAnalyzerRequest, error: addLogAnalyzerError } =
    useHttp(addLogAnalyzer);
  useEffect(() => {
    if (
      !customerIDError &&
      !siteIDError &&
      !routerIDError &&
      !analyzerNameError &&
      !logAnalyzerTypeError &&
      !descriptionError &&
      !searchTermError &&
      !emailError &&
      !teamsChannelError &&
      saveClicked
    ) {
      const logAnalzyerData = {
        customer: [customerID],
        site: [siteID],
        router: [routerID],
        analyzer_name: analyzerName,
        analyzer_type: logAnalyzerType,
        keyword: searchTerm,
        description: description,
        email: email,
        teams_channel: teamsChannel,
        status: logAnalyzerStatus,
      };
      addLogAnalyzerRequest(logAnalzyerData);
      setSaveClicked(false);
      if (!addLogAnalyzerError) {
        handleClose(true);
        handleCheckData(true);
        setAnalyzerNameError(false);
        setLogAnalyzerTypeError(false);
        setSearchTermError(false);
        setDescriptionError(false);
        setEmailError(false);
        setTeamsChannelError(false);
      }
    }
  }, [
    addLogAnalyzerError,
    addLogAnalyzerRequest,
    analyzerName,
    analyzerNameError,
    customerID,
    customerIDError,
    description,
    descriptionError,
    email,
    emailError,
    handleCheckData,
    handleClose,
    logAnalyzerStatus,
    logAnalyzerType,
    logAnalyzerTypeError,
    routerID,
    routerIDError,
    saveClicked,
    searchTerm,
    searchTermError,
    siteID,
    siteIDError,
    teamsChannel,
    teamsChannelError,
  ]);

  const logAnalyzerStatusChangeHanlder = (event) => {
    setlogAnalyzerStatus(event.target.checked);
  };

  const saveLogAnalyzerClickHandler = (event) => {
    event.preventDefault();
    if (customerID === "select" || customerID.trim() === "") {
      setCustomerIDError(true);
      setSaveClicked(false);
    }
    if (siteID === "select" || siteID.trim() === "") {
      setSiteIDError(true);
      setSaveClicked(false);
    }
    if (routerID === "select" || routerID.trim() === "") {
      setRouterIDError(true);
      setSaveClicked(false);
    }
    if (
      !analyzerName ||
      analyzerName.trim() === "" ||
      analyzerName.trim().length === 0
    ) {
      setAnalyzerNameError(true);
      setSaveClicked(false);
    }
    if (
      !logAnalyzerType ||
      logAnalyzerType.trim() === "" ||
      logAnalyzerType.trim().length === 0
    ) {
      setLogAnalyzerTypeError(true);
      setSaveClicked(false);
    }
    if (
      !searchTerm ||
      searchTerm.trim() === "" ||
      searchTerm.trim().length === 0
    ) {
      setSearchTermError(true);
      setSaveClicked(false);
    }
    if (
      !description ||
      description.trim() === "" ||
      description.trim().length === 0
    ) {
      setDescriptionError(true);
      setSaveClicked(false);
    }
    if (!email || email.trim() === "" || email.trim().length === 0) {
      setEmailError(true);
      setSaveClicked(false);
    }
    if (
      !teamsChannel ||
      teamsChannel.trim() === "" ||
      teamsChannel.trim().length === 0
    ) {
      setTeamsChannelError(true);
      setSaveClicked(false);
    }
    setSaveClicked(true);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add New Analyzer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomerSiteRouter
          setCustomerID={setCustomerID}
          customerIDError={customerIDError}
          setSiteID={setSiteID}
          siteIDError={siteIDError}
          setRouterID={setRouterID}
          routerIDError={routerIDError}
        />
        <Form>
          <Form.Group as={Row} className="mb-3" controlId="formAnalyzerName">
            <Form.Label column sm={2}>
              Ananlyzer Name
            </Form.Label>
            <Col sm={6}>
              <OverlayTrigger
                placement="bottom"
                overlay={renderAnalyzerNameErrorTooltip}
                target={target.current}
                show={analyzerNameError}
              >
                <Form.Control
                  type="text"
                  name="analyzerName"
                  value={analyzerName}
                  onChange={(event) => {
                    setAnalyzerName(event.target.value);
                  }}
                />
              </OverlayTrigger>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formLogAnalyzerType">
            <Form.Label column sm={2}>
              Log Analyzer Type
            </Form.Label>
            <Col sm={6}>
              <OverlayTrigger
                placement="bottom"
                overlay={renderLogAnalyzerTypeErrorTooltip}
                target={target.current}
                show={logAnalyzerTypeError}
              >
                <Form.Select
                  name="selectLogAnalyzerType"
                  onChange={(event) => {
                    setLogAnalyzerType(event.target.vale);
                  }}
                >
                  {logAnalyzerTypeList}
                </Form.Select>
              </OverlayTrigger>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formSearchTerm">
            <Form.Label column sm={2}>
              Search Term
            </Form.Label>
            <Col sm={6}>
              <OverlayTrigger
                placement="bottom"
                overlay={renderSearchTermErrorTooltip}
                target={target.current}
                show={searchTermError}
              >
                <Form.Select
                  name="selectSearchTerm"
                  onChange={(event) => {
                    setSearchTerm(event.target.vale);
                  }}
                >
                  {searchTermList}
                </Form.Select>
              </OverlayTrigger>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formDescription">
            <Form.Label column sm={2}>
              Description
            </Form.Label>
            <Col sm={6}>
              <OverlayTrigger
                placement="bottom"
                overlay={renderDescriptionErrorTooltip}
                target={target.current}
                show={descriptionError}
              >
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  value={description}
                  onChange={(event) => {
                    setDescription(event.target.value);
                  }}
                />
              </OverlayTrigger>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formEmail">
            <Form.Label column sm={2}>
              Email
            </Form.Label>
            <Col sm={6}>
              <OverlayTrigger
                placement="bottom"
                overlay={renderEmailErrorTooltip}
                target={target.current}
                show={emailError}
              >
                <Form.Control
                  type="email"
                  name="email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
              </OverlayTrigger>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formTeamsChannel">
            <Form.Label column sm={2}>
              Teams Channel
            </Form.Label>
            <Col sm={6}>
              <OverlayTrigger
                placement="bottom"
                overlay={renderTeamsChannelErrorTooltip}
                target={target.current}
                show={teamsChannelError}
              >
                <Form.Control
                  type="text"
                  name="teamsChannel"
                  value={teamsChannel}
                  onChange={(event) => {
                    setTeamsChannel(event.target.value);
                  }}
                />
              </OverlayTrigger>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="logAnalyzerStatus">
            <Form.Label as="legend" column sm={2}>
              Enable?
            </Form.Label>
            <Col sm={10}>
              <Form.Check
                type="switch"
                name="logAnalyzerStatus"
                checked={logAnalyzerStatus}
                onChange={logAnalyzerStatusChangeHanlder}
              />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={saveLogAnalyzerClickHandler}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddLogAnalyzer;
