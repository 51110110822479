import React, { Fragment, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import useHttp from "../../hooks/use-http";
import {
  getAllCustomers,
  getSiteByCustomer,
  getRouterBySite,
  addEventExtractionConfiguration,
} from "../../lib/api";
import { useHistory } from "react-router-dom";

const AddEventConfiguration = () => {
  const history = useHistory();
  const [customerList, setCustomerList] = useState([
    <option value="select" key="select">
      Select Customer
    </option>,
  ]);

  const [siteList, setSiteList] = useState([
    <option value="select" key="select">
      Select Site
    </option>,
  ]);

  const [routerList, setRouterList] = useState([
    <option value="select" key="select">
      Select Router
    </option>,
  ]);

  const [customerId, setCustomerId] = useState("");
  const [siteId, setSiteId] = useState("");
  const [routerId, setRouterId] = useState("");
  const [eventType, setEventType] = useState("");

  const { sendRequest, status, data, error } = useHttp(getAllCustomers);

  useEffect(() => {
    sendRequest();
  }, [sendRequest]);
  const {
    sendRequest: siteListByCustomerRequest,
    status: siteStatus,
    data: siteByCustomerData,
    error: siteError,
  } = useHttp(getSiteByCustomer);

  useEffect(() => {
    if (customerId) {
      siteListByCustomerRequest(customerId);
    } else {
      return;
    }
  }, [siteListByCustomerRequest, customerId]);

  useEffect(() => {
    if (siteByCustomerData) {
      setSiteList((prevstate) => [
        ...prevstate,
        siteByCustomerData.map((item, index) => {
          return (
            <option value={item.site_id} key={index}>
              {item.site_name}
            </option>
          );
        }),
      ]);
    } else {
      setSiteList([
        <option value="select" key="select">
          Select Site
        </option>,
      ]);
    }
  }, [setSiteList, siteByCustomerData]);

  const {
    sendRequest: routerListBySiteRequest,
    status: routerStatus,
    data: routerBySiteData,
    error: routerError,
  } = useHttp(getRouterBySite);

  useEffect(() => {
    if (routerBySiteData) {
      setRouterList((prevstate) => [
        ...prevstate,
        routerBySiteData.map((item, index) => {
          return (
            <option value={item.router_id} key={index}>
              {item.router_name}
            </option>
          );
        }),
      ]);
    } else {
      setSiteList([
        <option value="select" key="select">
          Select Router
        </option>,
      ]);
    }
  }, [setRouterList, routerBySiteData]);

  useEffect(() => {
    if (siteId) {
      routerListBySiteRequest(siteId);
    } else {
      return;
    }
  }, [routerListBySiteRequest, siteId]);

  const getCustomerList = (event) => {
    const customerData = data;
    setCustomerList((prevState) => [
      ...prevState,
      customerData.map((item, index) => {
        return (
          <option value={item.id} key={index}>
            {item.customer_name}
          </option>
        );
      }),
    ]);
  };

  const {
    sendRequest: addEventExtractionConfigRequest,
    data: addEventExtractionConfigData,
    status: addEventExtractionConfigStatus,
    error: addEventExtractionConfigError,
  } = useHttp(addEventExtractionConfiguration);

  const addEventExtractionConfigClickHandler = (event) => {
    event.preventDefault();
    const routerData = {
      customer: customerId,
      site: siteId,
      router: routerId,
      event_type: eventType,
    };
    addEventExtractionConfigRequest(routerData);
    if (!addEventExtractionConfigError) {
      history.push("/router/event_extraction");
    }
  };

  const cancelClickHandler = () => {
    history.push("/router/event_extraction");
  };
  return (
    <Fragment>
      <Card>
        <Card.Header as="h5">Add New Event</Card.Header>
        <Card.Body>
          <Form action="" method="post">
            <Form.Group as={Row} className="mb-3" controlId="selectCustomer">
              <Form.Label>Select Customer</Form.Label>
              <Col sm={10}>
                <Form.Select
                  name="selectCustomer"
                  onClick={getCustomerList}
                  onChange={(event) => {
                    setCustomerId(event.target.value);
                  }}
                >
                  {customerList}
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formSite">
              <Form.Label>Select Site</Form.Label>
              <Col sm={10}>
                <Form.Select
                  name="selectSite"
                  onChange={(event) => {
                    setSiteId(event.target.value);
                  }}
                >
                  {siteList}
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formSelectRouter">
              <Form.Label>Select Router</Form.Label>
              <Col sm={10}>
                <Form.Select
                  name="selectRouter"
                  onChange={(event) => {
                    setRouterId(event.target.value);
                  }}
                >
                  {routerList}
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formEventType">
              <Form.Label>Event Type</Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="eventType"
                  value={eventType}
                  onChange={(event) => {
                    setEventType(event.target.value);
                  }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Col sm={{ span: 10, offset: 2 }}>
                <Button
                  type="submit"
                  variant="primary"
                  onClick={addEventExtractionConfigClickHandler}
                >
                  Add
                </Button>{" "}
                <Button type="cancel" onClick={cancelClickHandler}>
                  Cancel
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default AddEventConfiguration;
