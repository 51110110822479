import React from 'react'
import {Card} from 'react-bootstrap'
import ListPassowrdResetLog from '../components/passwordReset/ListPassowrdResetLog'

const PasswordResetLog = () => {
  return (
    <Card>
        <Card.Header as="h5">Password Reset Logs</Card.Header>
        <Card.Body>
            <ListPassowrdResetLog/>
    </Card.Body>
    </Card>
  )
}

export default PasswordResetLog