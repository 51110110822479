import React from 'react'
import Card from 'react-bootstrap/Card'

import GetRouterOverviewLogs from '../components/routerOverview/GetRouterOverviewLogs'

const RouterOverviewLog = () => {
  return (
    <Card>
        <Card.Header as="h5">Router Overview Logs</Card.Header>
        <Card.Body>
    <GetRouterOverviewLogs/>
    </Card.Body>
    </Card>
  )
}

export default RouterOverviewLog