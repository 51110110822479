import React from 'react'
import {Card} from 'react-bootstrap'
import ShowSchedulerRunLogs from '../components/scheduler/ShowScheulerRunLogs'

const SchedulerLog = () => {
  return (
    <Card>
        <Card.Header as="h5">Scheduler Run Logs</Card.Header>
        <Card.Body>
            <ShowSchedulerRunLogs/>
    </Card.Body>
    </Card>
  )
}

export default SchedulerLog