import { Fragment } from "react";

import SideBar from "./SideBar";
import NewSideBar from "./NewSideBar";

import { Col, Container, Row } from "react-bootstrap";

const Layout = (props) => {
  return (
    <Fragment>
      {/* <SideBar/> */}
      <div>
        <Row>
          <Col xs="2">
            <NewSideBar />
          </Col>
          <Col xs="10">
            <main style={{ padding: "10px", alignContent:"center" }}>{props.children}</main>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Layout;
