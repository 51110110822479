import React, { Fragment, useEffect } from "react";
import useHttp from "../../hooks/use-http";
import { getConnectionLog } from "../../lib/api";
import FilterCustomerSiteRouter from "../customerSiteRouter/FilterCustomerSiteRouter";
import TableComponent from "../ui/Table";

const tableHeaders = [
  "Sl No",
  "Customer",
  "Site",
  "Router",
  "status",
  "Connection Attempts",
  "Attempt Date-Time",
];
const ShowConnectionLogs = () => {
  const { sendRequest, data, status, error } = useHttp(getConnectionLog);

  useEffect(() => {
    sendRequest();
  }, [sendRequest]);
  return (
    <Fragment>
      <FilterCustomerSiteRouter />
      <TableComponent
        tableHeaders={tableHeaders}
        data={data ? data : []}
        listOnly={true}
      />
    </Fragment>
  );
};

export default ShowConnectionLogs;
