import React from 'react'
import {Card} from 'react-bootstrap'
import ListEventExtractionLogs from '../components/eventExtraction/ListEventExtractionLogs'

const EventExtractionLog = () => {
  return (
    <Card>
        <Card.Header as="h5">Event Extraction Logs</Card.Header>
        <Card.Body>
            <ListEventExtractionLogs/>
    </Card.Body>
    </Card>
  )
}

export default EventExtractionLog