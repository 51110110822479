import React, { useEffect, useState, Fragment } from "react";
import useHttp from "../../hooks/use-http";
import { getPortsByRouter } from "../../lib/api";

import CustomerSiteRouter from "../customerSiteRouter/CustomerSiteRouter";
import TableComponent from "../ui/Table";
import AddRouterPorts from "./AddRouterPorts";

const tableHeaders = ["Sl No", "Port Name", "Description", "Status"];

const ListAddRouterPorts = (props) => {
  const [firstRender, setFirstRender] = useState(true);

  const [customerID, setCustomerID] = useState("");
  const [siteID, setSiteID] = useState("");
  const [routerID, setRouterID] = useState("");
  const [table, setTable] = useState(<div></div>);
  const [checkData, setCheckData] = useState(false);

 

  const { sendRequest:getPortByRouterRequest, data: PortByRouterData } = useHttp(getPortsByRouter);
  console.log(checkData)

  useEffect(() => {
    if (routerID || checkData) {
        getPortByRouterRequest(routerID);
      setFirstRender(false);
    }
  }, [getPortByRouterRequest, routerID, checkData]);

  const statusChangeHandler = () => {
    return;
  };

  console.log(customerID);

  const onDataClickHandler =(id)=>{
    console.log(id)
    props.show();
    props.setRouterPortID(id);
    return;
  }
  useEffect(() => {
    if (PortByRouterData && PortByRouterData.length > 0) {
      setTable(
        <Fragment>
          <TableComponent
            tableHeaders={tableHeaders}
            data={PortByRouterData?PortByRouterData:[]}
            onStatusChange={statusChangeHandler}
            listOnly={true}
            onDataClick= {onDataClickHandler}
          />
          <AddRouterPorts routerID={routerID} setCheckData={setCheckData}/>
         
        </Fragment>
      );
    } else if (
      !PortByRouterData &&
      !firstRender &&
      customerID &&
      siteID &&
      routerID
    ) {
      setTable(
        <Fragment>
          <AddRouterPorts routerID={routerID} setCheckData={setCheckData}/>
        </Fragment>
      );
    } else {
      setTable(<Fragment></Fragment>);
    }
  }, [PortByRouterData, checkData, customerID, firstRender, routerID, siteID]);
  return (
    <Fragment>
        <CustomerSiteRouter
          setCustomerID={setCustomerID}
          setSiteID={setSiteID}
          setRouterID={setRouterID}
        />
        {table}
        </Fragment>
  );
};

export default ListAddRouterPorts;
