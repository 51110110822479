import React, { Fragment, useState, useEffect } from "react";
import useHttp from "../../hooks/use-http";
import {
  addNewRouter,
  getActiveCustomers,
  getSiteByCustomer,
} from "../../lib/api";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

const AddRouter = React.memo(() => {
  const history = useHistory();
  const [customerList, setCustomerList] = useState([
    <option value="select" key="select">
      Select Customer
    </option>,
  ]);

  const [siteList, setSiteList] = useState([
    <option value="select" key="select">
      Select Site
    </option>,
  ]);

  const [customerId, setCustomerId] = useState("");
  const [siteId, setSiteId] = useState("");
  const [routerName, setRouterName] = useState("");
  const [routerAccessId, setRouterAccessId] = useState("");
  const [routerAccessPassword, setRouterAccessPassword] = useState("");
  const [routerAccessURL, setRouterAccessURL] = useState("");
  const [routerBrand, setRouterBrand] = useState("");
  const [routerFirmware, setRouterFirmware] = useState("");
  const [routerConnectionAttempts, setRouterConnectionAttempts] = useState("3");
  const [routerStatus, setRouterStatus] = useState(false);

  const {
    sendRequest: getAllCustomersRequest,
    status,
    data:customerData,
    error,
  } = useHttp(getActiveCustomers);

  useEffect(() => {
    getAllCustomersRequest();
  }, [getAllCustomersRequest]);

  const {
    sendRequest: siteListByCustomerRequest,
    status: siteStatus,
    data: siteByCustomerData,
    error: siteError,
  } = useHttp(getSiteByCustomer);

  useEffect(() => {
    if (customerId) {
      siteListByCustomerRequest(customerId);
    } else {
      return;
    }
  }, [siteListByCustomerRequest, customerId]);

  useEffect(() => {
    if (siteByCustomerData) {
      setSiteList((prevstate) => [
        ...prevstate,
        siteByCustomerData.map((item, index) => {
          return (
            <option value={item.site_id} key={index}>
              {item.site_name}
            </option>
          );
        }),
      ]);
    } else {
      setSiteList([
        <option value="select" key="select">
          Select Site
        </option>,
      ]);
    }
  }, [setSiteList, siteByCustomerData]);

  useEffect(() => {
    if (customerData) {
      setCustomerList((prevState) => [
        [<option value="select" key="select">
        Select Customer
      </option>,],
        customerData.map((item, index) => {
          return (
            <option value={item.id} key={index}>
              {item.customer_name}
            </option>
          );
        }),
      ]);
    } else {
      setCustomerList([
        <option value="select" key="select">
          Select Customer
        </option>,
      ]);
    }
  }, [customerData, setCustomerList]);



  const {
    sendRequest: addNewRouterRequest,
    data: addNewRouterData,
    status: addNewRouterStatus,
    error: addNewRouterError,
  } = useHttp(addNewRouter);

  const addRouterClickHandler = (event) => {
    event.preventDefault();
    const routerData = {
      customer: customerId,
      site: siteId,
      router_name: routerName,
      access_id: routerAccessId,
      access_pwd: routerAccessPassword,
      access_url: routerAccessURL,
      brand: routerBrand,
      firmware: routerFirmware,
      connection_attempts: routerConnectionAttempts,
      router_status: routerStatus,
    };
    addNewRouterRequest(routerData);
    if (!addNewRouterError) {
      history.push("/router");
    }
  };

  const cancelClickHandler = (event) => {
    event.preventDefault();
    history.push("/router");
  };

  return (
    <Fragment>
      <Card>
        <Card.Header as="h5">Add New Router</Card.Header>
        <Card.Body>
          <Form action="" method="post">
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="selectCustomer">Select Customer</Form.Label>
              <Col sm={10}>
                <Form.Select
                  name="selectCustomer"
                  id="selectCustomer"
                  onChange={(event) => {
                    setCustomerId(event.target.value);
                  }}
                >
                  {customerList}
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="selectCustomer">Select Site</Form.Label>
              <Col sm={10}>
                <Form.Select
                  name="selectSite"
                  id="selectSite"
                  onChange={(event) => {
                    setSiteId(event.target.value);
                  }}
                >
                  {siteList}
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="routerName">Router Name</Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="routerName"
                  id="routerName"
                  value={routerName}
                  onChange={(event) => {
                    setRouterName(event.target.value);
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="routerAccessId">Router Access ID</Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="routerAccessId"
                  id="routerAccessId"
                  value={routerAccessId}
                  onChange={(event) => {
                    setRouterAccessId(event.target.value);
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="routerAccessPassword">
                Router Access Password
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="routerAccessPassword"
                  id="routerAccessPassword"
                  value={routerAccessPassword}
                  onChange={(event) => {
                    setRouterAccessPassword(event.target.value);
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="routerAccessURL">
                Router Access URL
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="routerAccessURL"
                  id="routerAccessURL"
                  value={routerAccessURL}
                  onChange={(event) => {
                    setRouterAccessURL(event.target.value);
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="routerBrand">Router Brand</Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="routerBrand"
                  id="routerBrand"
                  value={routerBrand}
                  onChange={(event) => {
                    setRouterBrand(event.target.value);
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="routerFirmware">Router Firmware</Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="routerFirmware"
                  id="routerFirmware"
                  value={routerFirmware}
                  onChange={(event) => {
                    setRouterFirmware(event.target.value);
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="routerConnectionAttempts">
                Router Connection Attempts
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="routerConnectionAttempts"
                  id="routerConnectionAttempts"
                  value={routerConnectionAttempts}
                  onChange={(event) => {
                    setRouterConnectionAttempts(event.target.value);
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label as="legend" column sm={2}>
                Router Active
              </Form.Label>
              <Col sm={10}>
                <Form.Check
                  type="switch"
                  name="routerStatus"
                  id="routerStatus"
                  checked={routerStatus}
                  onChange={(event) => {
                    setRouterStatus(event.target.checked);
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Col sm={{ span: 10, offset: 2 }}>
                <Button
                  type="submit"
                  variant="primary"
                  onClick={addRouterClickHandler}
                >
                  Add
                </Button>{" "}
                <Button type="cancel" onClick={cancelClickHandler}>
                  Cancel
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </Fragment>
  );
});

export default AddRouter;
