import React from 'react'
import GetRouterOverview from '../components/routerOverview/GetRouterOverview'
import Card from 'react-bootstrap/Card'

const RouterOverview = () => {
  return (
    <Card>
        <Card.Header as="h5">Router overview</Card.Header>
        <GetRouterOverview/>

    </Card>
  )
}

export default RouterOverview