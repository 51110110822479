import React, { Fragment, useState, useEffect } from "react";
import TableComponent from "../ui/Table";
import FilterCustomerSiteRouter from "../customerSiteRouter/FilterCustomerSiteRouter";
import useHttp from "../../hooks/use-http";
import { getEventExtractionLogs } from "../../lib/api";

const tableHeaders = [
  "Sl No",
  "Customer Name",
  "Site Name",
  "Router Name",
  "Current Date-time",
  "File",
  "Log ID",
  "Log Date-time",
  "Event Type",
  "Details",
];

const ListEventExtractionLogs = ({ checkData, handleCheckData }) => {
    const [routerID, setRouterID] = useState("");
        const {
          sendRequest: getEventExtractionLogsRequest,
          data: eventExtractionLogsData,
        } = useHttp(getEventExtractionLogs);

    useEffect(()=>{
        console.log((routerID));
        if(routerID){
            getEventExtractionLogsRequest(routerID);
        }
    },[getEventExtractionLogsRequest, routerID])
  return (
    <Fragment>
        <FilterCustomerSiteRouter setRouterID={setRouterID}/>
      <TableComponent tableHeaders={tableHeaders} data={ eventExtractionLogsData ? eventExtractionLogsData :[]}  listOnly={true}/>
    </Fragment>
  );
};

export default ListEventExtractionLogs;
